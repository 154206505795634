import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
type Props = {
  onSelect: React.ReactEventHandler<HTMLInputElement> | undefined
  status: string
}

export default function UploadButton({ onSelect, status }: Props) {

  const { t } = useTranslation();
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      disabled={status === 'loading'}
    >
      {t('common.upload')}
      {status === 'loading' && <CircularProgress size={24} sx={{ position: 'absolute' }} />}
      <VisuallyHiddenInput type="file" onChange={onSelect}
        accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
    </Button>
  );
}