import React from "react";
import { Typography, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import { Theme } from "@mui/material";
import { Box } from "@mui/system";

const styles = (theme: Theme) => ({
  messageRow: {
    display: "flex"
  },
  messageRowRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  messageAssistant: {
    whiteSpace: "break-spaces",
    position: "relative",
    marginLeft: "20px",
    marginBottom: "10px",
    marginRight: "30px",
    padding: "10px",
    backgroundColor: '#f8e896',
    width: "auto",
    //height: "50px",
    textAlign: "left",
    
    border: "1px solid #dfd087",
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "15px solid #f8e896",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      left: "-15px"
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid #f8e896",
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      top: "-1px",
      left: "-17px"
    }
  },
  messageOrange: {
    whiteSpace: "break-spaces",
    position: "relative",
    marginRight: "20px",
    marginBottom: "10px",
    marginLeft: "30px",
    padding: "10px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: "auto",
    //height: "50px",
    textAlign: "left",

    border: "1px solid " + theme.palette.primary.main,
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid " + theme.palette.primary.main,
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      right: "-15px"
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid " + theme.palette.primary.main,
      
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      top: "-1px",
      right: "-17px"
    }
  },

  messageContent: {
    padding: 0,
    margin: 0
  },
  messageTimeStampRight: {
    position: "absolute",
    fontSize: ".85em",
    fontWeight: "300",
    marginTop: "10px",
    bottom: "-3px",
    right: "5px"
  },
  avatarNothing: {
    color: "transparent",
    backgroundColor: "transparent",
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  displayName: {
    marginLeft: "20px"
  }
});

export const MessageLeft = ({ message = '', timestamp = '', photoUrl = '', displayName = '' }) => {
  const theme = useTheme();
  const classes = styles(theme);

  return (
    <Box sx={classes.messageRow}>
      <Box>
        <Box sx={classes.messageAssistant}>
          <Box>
            <Typography variant="body1" sx={classes.messageContent}>{message}</Typography>
          </Box>
          <Box sx={classes.messageTimeStampRight}>{timestamp}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export const MessageRight = ({ message = '', timestamp = ''}) => {
  const theme = useTheme();
  const classes = styles(theme);

  return (
    <Box sx={classes.messageRowRight}>
      <Box sx={classes.messageOrange}>
        <Typography variant="body1" sx={classes.messageContent}>{message}</Typography>
        <Box sx={classes.messageTimeStampRight}>{timestamp}</Box>
      </Box>
    </Box>
  );
};
