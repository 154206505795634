import React, { useState } from 'react';
import ClientGoalAndPreferences from '../common/ClientGoalAndPreference';
import { Alert, Box, Button, DialogActions, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Client, ClientGoal } from '../../api';
import { useTranslation } from 'react-i18next';

type Props = {
  onSave: ({ mainGoal, trainingPreferences, availableDays, trainingDuration }: { mainGoal: string, trainingPreferences: string, availableDays: string, trainingDuration: string }) => void;
  status: string;
  onCancel: () => void;
  goal?: ClientGoal;
};

const GoalForm: React.FC<Props> = ({ onSave, status, goal, onCancel }: Props) => {
  const [mainGoal, setMainGoal] = useState<string>(goal?.mainGoal || '');
  const [trainingPreferences, setTrainingPreferences] = useState<string>(goal?.trainingPreferences || '');
  const [availableDays, setAvailableDays] = useState<string>(goal?.availableDays || '');
  const [trainingDuration, setTrainingDuration] = useState<string>(goal?.trainingDuration || '');
  const { t } = useTranslation();

  const handleMainGoalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMainGoal(event.target.value);
  };

  const handletrainingPreferencesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrainingPreferences(event.target.value);
  };

  const handleAvailableDaysChange = (event: SelectChangeEvent<string>) => {
    setAvailableDays(event.target.value);
  };
  const handleDurationChange = (event: SelectChangeEvent<string>) => {
    setTrainingDuration(event.target.value);
  };

  const handleSave = () => {
    onSave({ mainGoal, trainingPreferences, availableDays, trainingDuration });
  };

  return (
    <Box width="100%" px={[2, 4]} py={[1,2]}>
      <Box width="100%" mb={2}>
        <ClientGoalAndPreferences
          mainGoal={mainGoal}
          exercisePreference={trainingPreferences}
          premium={true}
          onMainGoalChange={handleMainGoalChange}
          onExercisePreferenceChange={handletrainingPreferencesChange}
          direction="row"
        />
        <Box>
          <Box display="flex" justifyContent="space-between" gap={2}>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="training-frequency-label">{t('GoalForm.weeklyFreq')}</InputLabel>
              <Select
                labelId="training-frequency-label"
                id="training-frequency"
                value={`${availableDays}`}
                label={t('GoalForm.weeklyFreq')}
                onChange={handleAvailableDaysChange}
              >
                <MenuItem value={'1'}>{t('GoalForm.oneTimePerWeek')} </MenuItem>
                <MenuItem value={'2'} >{t('GoalForm.xTimesPerWeek', { x: 2 })}</MenuItem>
                <MenuItem value={'3'}>{t('GoalForm.xTimesPerWeek', { x: 3 })} </MenuItem>
                <MenuItem value={'4'}>{t('GoalForm.xTimesPerWeek', { x: 4 })}</MenuItem>
                <MenuItem value={'5'}>{t('GoalForm.xTimesPerWeek', { x: 5 })}</MenuItem>
                <MenuItem value={'6'}>{t('GoalForm.xTimesPerWeek', { x: 6 })}</MenuItem>
                <MenuItem value={'7'}>{t('GoalForm.xTimesPerWeek', { x: 7 })}</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="training-duration-label">{t('GoalForm.trainingDuration')}</InputLabel>
              <Select
                labelId="training-duration-label"
                id="training-duration"
                value={trainingDuration}
                label={t('GoalForm.trainingDuration')}
                onChange={handleDurationChange}
              >
                <MenuItem value={"30"}>{t('GoalForm.30minutes')}</MenuItem>
                <MenuItem value={"45"}>{t('GoalForm.45minutes')}</MenuItem>
                <MenuItem value={"60"}>{t('GoalForm.60minutes')}</MenuItem>
                <MenuItem value={"75"}>{t('GoalForm.75minutes')}</MenuItem>
                <MenuItem value={"90"}>{t('GoalForm.90minutes')}</MenuItem>
                <MenuItem value={"105"}>{t('GoalForm.105minutes')}</MenuItem>
                <MenuItem value={"120"}>{t('GoalForm.120minutes')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      {status === 'error' && (
        <Alert sx={{ my: 2}} severity="error">{t('GoalForm.errorWhenSavingVerifyFields')}</Alert>
      )}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={status === 'loading'}>
        {t('GoalForm.update')}
        </Button>
        <Button variant="text" color="primary" onClick={onCancel} disabled={status === 'loading'}>
        {t('GoalForm.cancel')}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default GoalForm;