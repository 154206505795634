import React, { ReactElement, FC, useState, useContext, useEffect } from "react";
import { Alert, Card, CardMedia, CardContent, CardActions, CardActionArea, AlertTitle, Box, Button, Chip, Divider, Stack, TextField, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import api, { PaymentMethod, ProgramStep, Room, StripeCustomer } from "../api";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import { useTranslation } from "react-i18next";
import PartyExplosion from "../components/common/PartyExplosion";
import PaymentsContainer from "../containers/PaymentsContainer";

type PMState = {
  status: 'initial' | 'loading' | 'success' | 'error';
  error?: string;
  data?: StripeCustomer;
}

const expirationFormat = (expMonth: number, expYear: number) => {
  // add leading zero to month
  const month = expMonth < 10 ? `0${expMonth}` : expMonth;
  // get last two digits of year
  const year = expYear.toString().slice(-2);
  return `${month}/${year}`;
}

const BillingPage: FC<any> = (): ReactElement => {
  const [showThankYou, setShowThankYou] = useState(false);
  const { user, getUser } = useContext(UserContext);
  const [loadingLink, setLoadingLink] = useState(false);
  const [stripeCustomer, setStripeCustomer] = useState<PMState>({ status: 'initial', error: '' });
  const [params] = useSearchParams(); 
  const isProfessor = user.role === 'PROFESSOR'
  const { t } = useTranslation();
 
  const onAddCard = async () => {
    if (!user.id) return;
    setLoadingLink(true);
    const res = await api.stripe.getAddCardLink(user.id);
    if (res.success && res.data) {
      window.location.href = res.data.checkout;
    } else {
      setLoadingLink(false);
    }
  }

  const getStripeCustomer = async () => {
    if (!user.id) return;
    setStripeCustomer({ status: 'loading', error: '' })
    const res = await api.stripe.customer(user.id);
    if (res.success && res.data) {
      setStripeCustomer({ status: 'success', error: '', data: res.data })
    } else {
      setStripeCustomer({ status: 'error', error: res.error})
    }
  }
  const handleRemove = (paymentId: string) => async () => {
    if (!user.id) return;
    setLoadingLink(true);
    const res = await api.stripe.deleteCard(paymentId);
    if (res.success) {
      setStripeCustomer((values) => ({
        ...values,
        data: {
          ...values.data,
          cards: values.data?.cards?.filter((card) => card.id !== paymentId)
        } as StripeCustomer,
      }))
    }
    setLoadingLink(false);
  }
  const handleSetDefault = (paymentId: string) => async () => {
    if (!user.id) return;
    setLoadingLink(true);
    const res = await api.stripe.setAsDefault(user.id, paymentId);
    if (res.success) {
      setStripeCustomer((values) => ({
        ...values,
        data: {
          ...values.data,
          cards: values.data?.cards?.map((card) => card.id === paymentId ? ({ ...card, isDefault: true }) : ({ ...card, isDefault: false }))
        } as StripeCustomer,
      }))
    }
    setLoadingLink(false);
  }


  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    const payment = params.get('payment_complete') ? params.get('payment_complete')?.includes('true') : false;
    if (payment) {
      setShowThankYou(true);
    }
    getStripeCustomer();
  }, [user.id]);

  const lastBandCode = user.client?.bands?.length  ? user.client?.bands[user.client?.bands.length-1].band.code : '';

  if (!user?.name) {
    return (
      <Box>
        <Skeleton variant="rectangular" width="100%" height={50}  sx={{ mb: 2 }} animation="wave"/>
        <Skeleton variant="rectangular" width={300} height={300} sx={{ mb: 2 }} animation="wave" />
      </Box>
    )
  }
  return (
    <Box pb={3}>
      <PageTitle title={t('BillingPage.title')} />
      {showThankYou && (
        <>
          <Dialog open={showThankYou} hideBackdrop>
            <DialogTitle>{t('Profile.thankYou')}</DialogTitle>
            <DialogContent>
              {t('Profile.thankYouForYourPurchase')}
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setShowThankYou(false)}>
                Continuar
              </Button>
            </DialogActions>
          </Dialog>
          <PartyExplosion duration={10000}/>
        </>
      )}
      {isProfessor && (
        <>
        <Box mt={3} ml={1} mb={3}>
          <Typography variant="h4">{t('Profile.myPaymentMethods')}</Typography>
          {stripeCustomer.status === 'loading' ? (
            <Box>
              <Skeleton variant="rectangular" width={300} height={200} sx={{ mb: 2 }} animation="wave" />
            </Box>
          ) : (
            <>
            <Box display="flex" gap={3} flexWrap="wrap">
              {stripeCustomer.data?.cards?.length ? (
                stripeCustomer.data?.cards.map((card: PaymentMethod) => (
                  <Card key={card.id} sx={{ minWidth: 320, maxWidth: 350, position: 'relative' }}>
                    <CardContent>
                      <Typography variant="h3">{card.brand}</Typography>
                      <Typography variant="h6" fontWeight={'bold'} mt={4} sx={{ textAlign: 'center' }}>XXXX-XXXX-XXXX-{card.last4}</Typography>
                      <Typography variant="body2" sx={{ textAlign: 'center' }}>
                        <b>{t('BillingPage.expires')}</b>{expirationFormat(card.expMonth, card.expYear)}
                      </Typography>
                    </CardContent>
                    <CardActions>
                        {!card.isDefault && (
                          <Button size="small" onClick={handleRemove(card.id)} disabled={card.isDefault || loadingLink}>
                          {t('BillingPage.remove')}
                        </Button>
                        )}
                        {!card.isDefault ? (
                          <Button size="small" onClick={handleSetDefault(card.id)} disabled={loadingLink}>
                            {t('BillingPage.setAsDefault')}
                          </Button>
                        ) : (
                          <Chip size="small" color="primary" label={t('BillingPage.isDefault')} />
                        )}
                    </CardActions>
                  </Card>
                ))) : (
                  <Typography>{t('Profile.noPaymentMethods')}</Typography>
                )}
            </Box> 
            <Box mt={3}>
              <Button variant="contained" onClick={onAddCard} disabled={loadingLink}>{t('Profile.addPaymentMethod')}</Button>
            </Box>
            </>
          )}
        </Box>

        <Divider />
        <Box mt={3} ml={1} mb={3}>
          <Typography variant="h4">{t('Profile.myPayments')}</Typography>
          <PaymentsContainer userId={user.id} />
        </Box>
      </>
      )}
    </Box>
  );
};

export default BillingPage;