import React, { useContext, useState } from 'react';
import { TextField, Button, Box, Paper, Typography, Container, Alert, AlertTitle, Zoom, Slide } from '@mui/material';
import api from '../api';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import { useTranslation } from 'react-i18next';

const SignInContainer = () => {

  const { user } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [page, setPage] = useState('login');
  const [info, setInfo] = useState('');
  const [result, setResult] = useState({ status: 'initial', error: '' });
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onLogin = async (e: any) => {
    setResult({ status: 'loading', error: '' })
    e.preventDefault();
    const res = await api.auth.login(email, password)
    
    if (res.success) {
      navigate('/about', { replace: true });
    }else {
      setResult({ status: 'error', error: res.error })
    }
  };

  const onResetPassword = async(e: any) => {
    setResult({ status: 'loading', error: '' })
    e.preventDefault();
    const res = await api.auth.resetPassword(email)
    
    if (res.success) {
      setPage('login');
      setResult({ status: 'initial', error: '' })
      setInfo(t('SignIn.openEmailBox'));
      setTimeout(() => {
        setInfo('');
      }, 3000)
    }else {
      setResult({ status: 'error', error: t('SignIn.couldNotReset') })
    }
  }
  const handleSubmit = page === 'login' ? onLogin : onResetPassword;
  const handleToggle = (page: string) => () => setPage(page === 'login' ? 'reset' : 'login');


  if (user.id) {
    return <Navigate to="about" />;
  }
  return (
    
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ backgroundImage: 'url(/stocks/ai_gym1.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center',  width: '100%', height: '100vh', position: 'absolute', top: 0 }}>
      <Box width={['100%', '1200px']} py={[4, 0]} height={['auto', '800px']} sx={{ background: 'rgba(0,0,0, 0.7)' }} display="flex" flexDirection="column" alignItems="center" justifyContent="center">  <Box sx={{ width: ["100%", "500px"], margin: '0 auto' }}>
        <Slide direction="right" in={page === 'login' || page === 'reset'}>
          <Box sx={{ padding: 4, margin: ['0', '90px auto'], display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src="/logo_icon.png" width="60px" />
            <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '16px' }}>
              <TextField
                size="medium"
                margin="normal"
                required
                fullWidth
                label={t('SignIn.email')}
                name="email"
                autoComplete="email"
                value={email}
                variant="filled"
                InputProps={{ style: { backgroundColor: 'background' }}}
                onChange={(e) => setEmail(e.target.value)}
              />
              {page === 'login' ? (
                <TextField
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  label={t('SignIn.password')}
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  value={password}

                  InputProps={{ style: { backgroundColor: 'background' }}}
                  onChange={(e) => setPassword(e.target.value)}
                />) : (<></>)
              }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={result.status === 'loading'}
                style={{ margin: '24px 0 16px' }}
              >
                {page === 'login' ? t('SignIn.login') : t('SignIn.resetPassword')}
              </Button>
              {result.error && (
                <Alert variant="filled" severity="error">
                  <AlertTitle>{t('SignIn.invalidCredentials')}</AlertTitle>
                </Alert>
              )}
              {info && (
                <Alert variant="filled" severity="success">
                  <AlertTitle>{info}</AlertTitle>
                </Alert>
              )}
              <Box mt={2}>
                <Button
                  onClick={handleToggle(page)}
                  fullWidth
                  variant="outlined" color="primary" size="large" sx={{ backgroundColor: 'white' }}
                >
                  {page === 'login' ?  t('SignIn.haveYouForgottenPass') : t('SignIn.back')}
                </Button>
              </Box>
            </form>
            {<Box mt={2} display="flex" justifyContent="flex-end" width="100%">
              <Link to="/">
                <Button variant="text" color="secondary">
                  {t('SignIn.dontHaveAccount')}
                </Button>
              </Link>
            </Box>}
          </Box>
        </Slide>
      </Box>
    </Box>
  </Box>
  );
};

export default SignInContainer;
