import React, { useEffect, useState } from 'react';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, useTheme } from '@mui/material';
import EndomorphIcon from '../../icons/endomorph';
import MesomorphIcon from '../../icons/mesomorph';
import EctomorphIcon from '../../icons/ectomorph';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { useTranslation } from 'react-i18next';

const Step2: React.FC = () => {
  const { setData, currentStep, data } = useWizard();
  const [gender, setGender] = useState('MASCULINE');
  const [bodyType, setBodyType] = useState('');
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const white = theme.palette.error.main;
  const text = theme.palette.text.primary;
  const { t } = useTranslation(); 

  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender((event.target as HTMLInputElement).value);
  };

  const handleBodyTypeChange = (type: string) => {
    setBodyType(type);
  };

  useEffect(() => {
    setData({ ...data, gender, bodytype: bodyType});
  }, [gender, bodyType]);

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t('WizardStep2.weNeedData')}
        </Typography>
      <Typography variant="subtitle1" mt={2}>
      {t('WizardStep2.toCustomizeWeNeed')}
      </Typography>
      
      <Typography variant="subtitle1" color="grey">{t('WizardStep2.sex')}</Typography>
      <FormControl component="fieldset">
        <RadioGroup row aria-label="gender" name="gender" value={gender} onChange={handleGenderChange}>
          <FormControlLabel value="MASCULINE" control={<Radio />} label={t('WizardStep2.maleBodyLabel')} />
          <FormControlLabel value="FEMENINE" control={<Radio />} label={t('WizardStep2.femaleBodyLabel')} />
          <FormControlLabel value="UNDEFINED" control={<Radio />} label={t('WizardStep2.undefinedLabel')} />
        </RadioGroup>
      </FormControl>
      <Typography variant="subtitle1" color="grey" sx={{ marginTop: 3 }}>{t('WizardStep2.bodyType')}</Typography>
      
      <Box display="flex" justifyContent="space-around" mt={2} gap={1}>
        <Box onClick={() => handleBodyTypeChange('endomorph')} className={`body-type ${bodyType === 'endomorph' ? 'selected' : ''}`} flex="1" sx={{ height: 360, cursor: 'pointer' }}>
          <EndomorphIcon selected={bodyType === 'endomorph'} gender={gender} />
        </Box>
        <Box onClick={() => handleBodyTypeChange('mesomorph')} className={`body-type ${bodyType === 'mesomorph' ? 'selected' : ''}`} flex="1" sx={{ height: 360, cursor: 'pointer' }}>
          <MesomorphIcon selected={bodyType === 'mesomorph'} gender={gender} />
        </Box>
        <Box onClick={() => handleBodyTypeChange('ectomorph')} className={`body-type ${bodyType === 'ectomorph' ? 'selected' : ''}`} flex="1" sx={{ height: 360, cursor: 'pointer' }}>
          <EctomorphIcon selected={bodyType === 'ectomorph'} gender={gender} />
        </Box>
      </Box>
    </Box>
  );
};

export default Step2;