import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import HeartRateContainer from '../containers/HeartRateContainer';
import { ScaleLinear } from 'd3';

export type DataPoint = {
  x: number;
  y: number;
};

export type LineChartProps = {
  points: Array<DataPoint>, // effort expected for the class
  realPoints?: Array<DataPoint>, // effort real for the user
  width?: number,
  height?: number,
  xScale: Function,
  yScale: Function,
  onChartRef?: Function,
}

const EffortLineChart = ({ points = [], realPoints = [], width = 500, height = 300, xScale, yScale, onChartRef = () => {} }: LineChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const data: DataPoint[] = points;

    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const w = width - margin.left - margin.right;
    const h = height - margin.top - margin.bottom;

    
    const line = d3.line<DataPoint>()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y));

    const svg = d3.select(chartRef.current)
      ?.append("svg")
      .attr("width", w + margin.left + margin.right)
      .attr("height", h + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const colors = d3.interpolate('#ec5d30', '#ab293d');
    const barHeight = h / 10;

    for (let i = 0; i < 10; i++) {
      const color = colors(i / 9);
      const gradient = svg?.append("linearGradient")
        .attr("id", `gradient-${i}`)
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "0%")
        .attr("y2", "100%");

      gradient?.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", color);

      gradient?.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", color);

      svg?.append("rect")
        .attr("width", w)
        .attr("height", barHeight)
        .attr("y", i * barHeight)
        .attr("fill", `url(#gradient-${i})`);
    }

    const dataPath = svg?.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "white")
      .attr("stroke-width", 3)
      .attr("d", line);
    // we add the animation only if it is for classes
    if (!realPoints.length) {
      // Calculate the total length of the path
      const totalLength = dataPath.node()?.getTotalLength();

      // Set the initial style of the line for the animation
      dataPath
        .attr("stroke-dasharray", totalLength + " " + totalLength)
        .attr("stroke-dashoffset", totalLength || 300)
        .transition()
        .duration(1500) // Duration of the animation in milliseconds
        .ease(d3.easeLinear) // Easing function for the animation
        .attr("stroke-dashoffset", 0); // Animate the stroke-dashoffset to 0
    }
    if (realPoints.length) {
      const line = d3.line<DataPoint>()
        .x(d => xScale(d.x))
        .y(d => yScale(d.y));
      // Append the path element
      const linePath = svg
        .append("path")
        .datum(realPoints)
        .attr("fill", "none")
        .attr("stroke", "grey")
        .attr("stroke-width", 2)
        .attr("d", line);

      // Calculate the total length of the path
      const totalLength = linePath.node()?.getTotalLength();

      // Set the initial style of the line for the animation
      linePath
        .attr("stroke-dasharray", totalLength + " " + totalLength)
        .attr("stroke-dashoffset", totalLength || 300)
        .transition()
        .duration(1500) // Duration of the animation in milliseconds
        .ease(d3.easeLinear) // Easing function for the animation
        .attr("stroke-dashoffset", 0); // Animate the stroke-dashoffset to 0

    }
    // send to the parent component the chart
    onChartRef(chartRef.current);

  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div ref={chartRef} />
    </div>
  )
};

export default EffortLineChart;
