import React, { ReactElement, FC, useEffect, useContext, useState } from "react";
import { Box } from "@mui/material";
import api, { Payment, Room, TrainingSession } from "../api";
import PaymentsTableComponent from "../components/PaymentsTableComponent";

type PaymentsState = {
  data: Payment[],
  status: string,
  error?: string,
  next?: boolean,
  before?: boolean,
}
type ContainerProps = {
  userId?: number,
};

const PaymentsContainer: FC<ContainerProps> = ({ userId }): ReactElement => {
  const [payments, setPayments] = useState<PaymentsState>({ data: [], status: 'initial', next: true, before: false });

  const getpayments = async (prev?: string, next?: string) => {
    // TODO: this goes to start
    if (!userId) return;
    setPayments((values) => ({ ...values, data: [], status: 'loading'  }));
    const res = await api.stripe.getUserPayments(userId, 5, prev, next);

    if (res.success && res.data) {
      const pages = ((!next && !prev) || next) ? { next: res.hasMore, before: !!next } : { before: res.hasMore, next: !!prev };
      setPayments((values) => ({ ...values, data: res.data, status: 'success', ...pages }));
    }else {
      setPayments({ data: [], status: 'error', error: res.error })
    }
  }

  const onPrevious = () => {
    getpayments(payments.data[0].id, undefined);
  }
  const onNext = () => {
    getpayments(undefined, payments.data[payments.data.length - 1].id);
  }
console.log('payments', payments.next)
  useEffect(() => {
    if (userId)
      getpayments();
  }, []);
  return (
    <PaymentsTableComponent onNext={payments.next ? onNext : undefined} onPrevious={payments.before ? onPrevious : undefined} payments={payments.data} status={payments.status} error={payments.error} />
  );
};

export default PaymentsContainer;