import React, { ReactElement, FC, useEffect, useState, useContext } from "react";
import { Box, Button,  List, ListItemButton, ListItemAvatar, ListItemText, Typography, SpeedDial, SpeedDialIcon, Divider, ListItemSecondaryAction, Alert, AlertTitle } from "@mui/material";
import api, { MultipleTraining } from "../api";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import { FitnessCenter } from "@mui/icons-material";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { useTranslation } from "react-i18next";

type MultipleTrainingState = {
  data: MultipleTraining[]
  error?: string
  status: string
}
const PAGE_SIZE = 20;
const MultipleTrainingsListPage: FC<any> = (): ReactElement => {
  const [multipleTrainings, setMultipleTrainings] = useState<Array<MultipleTraining>>([]);
  const [request, setRequest] = useState({ page: 0, status: 'initial' });
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isProfessor = user.role === 'PROFESSOR';

  const getNextMultipleTrainings = async () => {
    setRequest({ page: request.page + 1, status: "loading" })
    const res = await api.multipleTrainings.get(request.page, PAGE_SIZE);
    if (res.success) {
      setMultipleTrainings(multipleTrainings.concat(res.data));
      if (res.data?.length) {
        setRequest((values) => ({ ...values, status: 'success' }));
      }else {
        setRequest((values) => ({ ...values, status: 'end' }));
      }
    }
  }

  useEffect(() => {
    getNextMultipleTrainings();
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
    >

      <PageTitle title={t('MultipleTrainingsList.title')} />
      {isProfessor && user.validated && (
        <SpeedDial
          onClick={() => navigate("/multiple-trainings/add")}
          ariaLabel="create batch"
          sx={{ position: 'fixed', bottom: 75, right: 16 }}
          icon={<SpeedDialIcon />}
        />
      )}
      {isProfessor && !user.validated && (
        <Alert severity="info">
          <AlertTitle>{t('MultipleTrainingsList.validateYourAccount')}</AlertTitle>
        </Alert>
      )}
      <List
        sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}
        component="nav"
      >
        {multipleTrainings.map((p: MultipleTraining) => (
          <Link key={p.id} to={`/multiple-trainings/${p.id}`}>
            <ListItemButton key={p.id} >
              <ListItemAvatar>
                <DynamicFeedIcon />
              </ListItemAvatar>
              <ListItemText
                primary={p.name}
                secondary={`${p.currentCompleted}/${p.totalTrainings} ${t('MultipleTrainingsList.generated')}`}
              />
              <Divider />
            </ListItemButton>
          </Link>
        ))}
      </List>

      {/*request.status !== 'end' && (<Button variant="contained" onClick={getNextMultipleTrainings}>Ver más</Button>)*/}
    </Box>
  );
};

export default MultipleTrainingsListPage;