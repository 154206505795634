import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Gym } from '../../api';
import { useRef } from 'react';


export const GymCard = ({ gym, sx, logoRef }: { gym: Gym, sx?: any, logoRef?: React.RefObject<HTMLDivElement> }) => {
  const { t } = useTranslation();
  const style = sx || { minWidth: 250, maxWidth: 345,minHeight: 230, position: 'relative' };
  
  return (
    <Card sx={style} ref={logoRef}>
      <CardContent>
        <Typography variant="h6" component="div">
          {gym.name}
        </Typography>
        <CardActionArea>
          <CardMedia
            component="img"
            loading="lazy"
            height="150"
            image={gym.logoUrl || '/logo_2_out.png'}
            sx={{ objectFit: 'contain' }}
          />
        </CardActionArea>
      </CardContent>

      <CardActions sx={{ bottom: 0, position: 'absolute' }}>
        <Button size="small" disabled>
          {t('Profile.editImage')}
        </Button>
      </CardActions>
    </Card>
  );
}