import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Card, CardContent, Typography, CardActions, Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Box, Slide, List, Alert, SpeedDial, SpeedDialIcon, LinearProgress, Divider, Skeleton } from '@mui/material';
import api, { Gym, User } from '../../api';
import { useTranslation } from 'react-i18next';
import './effects.css';
import { useNavigate } from 'react-router-dom';
import { EditExerciseCardTutorial } from './EditExerciseCardTutorial';
import AddExerciseButton from '../workweek-editor/AddExercise';
import { UserContext } from '../../contexts/userContext';

interface EditPlanificationTutorialProps {
  gym?: Gym;
  isEditing?: boolean;
}

const EditPlanificationTutorial: React.FC<EditPlanificationTutorialProps> = ({ gym, isEditing,  }) => {
  const [step, setStep] = React.useState('initial');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getUser } = useContext(UserContext);

  const handleFinishTutorial = async () => {
    if (!gym) { return; }
    setStep('initial');
    await api.gyms.setTutorialStep(gym.id, 'firstPlanification');
    getUser();
  }
  useEffect(() => {
    if (!gym?.tutorialSteps?.firstPlanification) {
      setStep('start');
    }
  }, []);
  useEffect(() => {
   
  }, [step])
  
  useEffect(() => {
    if (isEditing) {
      setStep('edit1');
    }
  }, [isEditing])
  
  return (
    <>
      {step !== 'initial' && (
        <Backdrop open sx={{ zIndex: 10000 }}>
          {step === 'start' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.editPlanificationTitle')}</DialogTitle>
                <DialogContent>
                  {t('Tutorial.editPlanificationDescription1')}

                  <Box px={2} mb={2} className="glow-effect">
                    <LinearProgress variant="determinate" value={20} sx={{ my: 2 }} />

                    <Typography variant="body1">{t('Tutorial.editPlanificationProgress')}</Typography>
                  </Box>
                  <Typography variant="body1">{t('Tutorial.editPlanificationDescription2')}</Typography>
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={handleFinishTutorial}>
                    {t('Tutorial.skip')}
                  </Button>
                  <Button onClick={() => setStep('next')}>
                    {t('Tutorial.next')}
                  </Button>
                </DialogActions>
              </Dialog>
          </>
          )}
          {step === 'next' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.editPlanificationTitle')}</DialogTitle>
                <DialogContent>
                 
                  <Typography variant="body1">{t('Tutorial.editPlanificationDescription3')}</Typography>
                  <Box display="flex" flexDirection="column" p={2} pt={0} mt={2} width="100%">
                    <Divider />
                    <Box display="flex" justifyContent="space-between" pt={3} alignItems="center">
                      <Button variant="outlined">
                        {t('Wizard.goBack')}
                      </Button>
                      <Typography variant="body1" color="secondary">
                        {t('Wizard.stepOf', { currentStep: 1, total: 7 })}
                      </Typography>
                      <Button variant="contained">
                        {t('Wizard.next')}
                      </Button>
                    </Box>
                  </Box>
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={() => setStep('initial')}>
                    {t('Tutorial.understood')}
                  </Button>
                </DialogActions>
              </Dialog>
          </>
          )}

          {step === 'edit1' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.editPlanificationReadyTitle')}</DialogTitle>
                <DialogContent>
                 
                  <Typography variant="body1">{t('Tutorial.editPlanificationReadyDnD')}</Typography>
                  <Box width="100%" display="flex" justifyContent="center">
                    <Box className="list-container">
                      <Box className="move-down" mt={1}>
                        <Skeleton variant="rectangular" width={300} height={50} />
                      </Box>
                      <Box className="list-item" mt={1}>
                        <Skeleton variant="rectangular" width={300} height={50} />
                      </Box>
                      <Box className="move-item" mt={1}>
                        <Skeleton variant="rectangular" width={300} height={50} />
                      </Box>
                    </Box>
                  </Box>
                  
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={() => setStep('edit2')}>
                    {t('Tutorial.next')}
                  </Button>
                </DialogActions>
              </Dialog>
          </>
          )}
          {step === 'edit2' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.editPlanificationReadyTitle')}</DialogTitle>
                <DialogContent>
                 
                  <Typography variant="body1">{t('Tutorial.editPlanificationReadyExercise')}</Typography>
                  <Box>
                    <EditExerciseCardTutorial />
                  </Box>
                  
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={() => setStep('edit3')}>
                    {t('Tutorial.next')}
                  </Button>
                </DialogActions>
              </Dialog>
          </>
          )}

          {step === 'edit3' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.editPlanificationReadyTitle')}</DialogTitle>
                <DialogContent>
                 
                  <Typography variant="body1">{t('Tutorial.editPlanificationReadyExerciseNew')}</Typography>
                  <Typography variant="body1">{t('Tutorial.editPlanificationReadyExerciseNew2')}</Typography>
                  <Box my={2}>

                    <AddExerciseButton day={1} />
                  </Box>
                  <Typography variant="body1">{t('Tutorial.editPlanificationReadyExerciseNew3')}</Typography>
                  
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={() => setStep('initial')}>
                    {t('Tutorial.understood')}
                  </Button>
                </DialogActions>
              </Dialog>
          </>
          )}
         </Backdrop>
      )}
    </>
  );
};

export default EditPlanificationTutorial;