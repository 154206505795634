import axios from 'axios';
import config from './../config';

const axiosInstance = axios.create({
  baseURL: config.baseURL, // Set your API base URL here
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Set token in the request headers
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
