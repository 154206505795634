import React, { useEffect } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { useTranslation } from 'react-i18next';

const Step5: React.FC = () => {
  const [previousExperience, setPreviousExperience] = React.useState<string>('');
  const [previousInjuries, setPreviousInjuries] = React.useState<string>('');
  const [previousInjuriesBool, setPreviousInjuriesBool] = React.useState<string>('');
  const { setData, data } = useWizard();

  const { t } = useTranslation();
  const handleExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousExperience((event.target as HTMLInputElement).value);
  };

  const handlepreviousInjuriesBoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousInjuriesBool((event.target as HTMLInputElement).value);
  };


  const handlePreviousInjuriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousInjuries((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    setData({ ...data, previousExperience, injuries: previousInjuries });
  }, [previousExperience, previousInjuries]);

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t('WizardStep5.prevExperienceCrucial')}
      </Typography>
      <Typography variant="subtitle1" color="grey">
      {t('WizardStep5.selectLevel')}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="previousExperience"
          name="previousExperience"
          value={previousExperience}
          onChange={handleExperienceChange}
          sx={{ flexDirection: 'column' }}
        >
        <FormControlLabel value={t('HealthForm.sedentaryValue')} control={<Radio />} label={t('HealthForm.sedentaryLabel')} />
        <FormControlLabel value={t('HealthForm.begginerValue')} control={<Radio />} label={t('HealthForm.begginerLabel')} />
        <FormControlLabel value={t('HealthForm.mediumValue')} control={<Radio />} label={t('HealthForm.mediumLabel')} />
        <FormControlLabel value={t('HealthForm.advancedValue')} control={<Radio />} label={t('HealthForm.advancedLabel')} />
        </RadioGroup>
      </FormControl>
      <Typography variant="subtitle1" color="grey" sx={{ marginTop: 2 }}>
      {t('HealthForm.selectAdditionalOption')}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="previousInjuries"
          name="previousInjuries"
          value={previousInjuriesBool}
          onChange={handlepreviousInjuriesBoolChange}
          sx={{ flexDirection: 'column' }}
        >
        <FormControlLabel value={t('HealthForm.noInjuriesValue')} control={<Radio />} label={t('HealthForm.noInjuriesLabel')} />
        <FormControlLabel value={t('HealthForm.yesInjuriesValue')} control={<Radio />} label={t('HealthForm.yesInjuriesLabel')} />
        </RadioGroup>
      </FormControl>
      {previousInjuriesBool === 'si' && (
        <TextField
        label={t('HealthForm.pleaseMoreDetails')}
          variant="outlined"
          value={previousInjuries}
          onChange={handlePreviousInjuriesChange}
          fullWidth
          sx={{ marginTop: 2 }}
        />
      )}
    </Box>
  );
};

export default Step5;
