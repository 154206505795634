import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { grey } from '@mui/material/colors';
import { useTheme } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';

type Step1Props = {
  onNext: () => void;
};
const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const theme = useTheme();
  const { t } = useTranslation(); 
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4" paragraph textAlign="center">
        {t('WizardStep1.welcome')}
      </Typography>
      <Typography variant="body1" paragraph textAlign="center">
      {t('WizardStep1.description')}
      </Typography>
      <Button variant="contained" onClick={onNext}>
        {t('WizardStep1.startFree')}
      </Button>
      <Box display="flex" flexDirection="column" mt={['100px', '200px']} alignItems="center" maxWidth={['100%', '300px']} padding={[3, 4]} borderRadius={'20px'} 
        sx={{ 
          background: theme.palette.grey[200],
        }}>
        <Typography variant="body1" textAlign="center" fontWeight="bold" sx={{ marginBottom: 3 }}>
        {t('WizardStep1.alreadyKnow')}
        
        </Typography>
        <a href="https://wizfit.ai/inicio/#precios" target="_blank" rel="noreferrer">
          <Button variant="outlined" sx={{ paddingTop: '5px'}}>
          {t('WizardStep1.seePremium')}
          </Button>
        </a>

      </Box>
    </Box>
  );
};

export default Step1;