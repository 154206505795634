
type RecordType = { id: any; recordType: any; mediaType: any; encoding: any; data: any; };

export class NfcReader {
  abortController: any;

  async scanTag () {
    
    return new Promise((resolve, reject) => {
      try {
        // @ts-ignore
        if (typeof NDEFReader === 'undefined') {
          throw new Error('NFC not supported');
        }
        // @ts-ignore 
        const reader = new NDEFReader();
        this.abortController = new AbortController();

        reader.scan({signal: this.abortController.signal});

        reader.addEventListener('reading', (e: any) => resolve(this.readTag(e)));

        reader.addEventListener('readingerror', (e: any) => {
          console.log('error reading tag', e);
          reject(e);
        });
      }
      catch(e) {
        console.log('error scanning tag:', e);
        reject(e);
      }
    });
  }
  
  stopScan()  {
    this.abortController.abort();
  
  }


  readTag ({ message }: any) {
    const {records} = message;

    return records.map((record: RecordType) => {
      const {id, recordType, mediaType, encoding, data} = record;

      const decoder = encoding ? new TextDecoder(encoding) : new TextDecoder();

      
      return decoder.decode(data);
    });
  }

}
