export const getColorByEffort = (effort: number) => {
  if (effort <= 60) {
    return '#9ACD32';
  } else if (effort <= 70) {
    return '#FFA500';
  } else if (effort <= 80) {
    return '#B22222';
  } else {
    return '#9400D3';
  }
}