import React, { useState } from 'react'
import { Button, Theme, useTheme } from '@mui/material';

import { Box, Paper, Typography, Container, Alert, AlertTitle } from '@mui/material';
import { TextInput } from './TextInput';
import { MessageLeft, MessageRight } from './Message';
import TypingAnimation from './TypingAnimation';
import { Program } from '../../api';
import TrainingProgramDetailsComponent from '../TrainingDetails';

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    paddingTop: 10,
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    paddingBottom: '80px',
    paddingLeft: 1,
    paddingRight: 1,
  },
  messagesBody: {
    width: '100%',
  }
});

type RequestType = {
  status: string,
  program?: Program,
};

type ConversationProps = {
  onSendMessage: Function,
  messages: Array<any>,
  request: RequestType,
};

type ProgramDraftState = {
  program?: Program
}

export default function ConverstationComponent({ onSendMessage, messages, request }: ConversationProps) {
  const theme = useTheme();
  const classes = styles(theme);
  const [programDraft, setProgramDraft] = useState<ProgramDraftState>({});
  const handleSeeProgram = () => {
    setProgramDraft({ program: request.program })
  }
  const handleTryAgain = () => {
    setProgramDraft({});
    onSendMessage('No me convence');
  }
  const handleSendMessage = (msg: string) => {
    onSendMessage(msg);
    setProgramDraft({});
  }
  return (
    <Container sx={classes.container}>
      <Box flex={10}>
        {messages.map((m, index) =>
          m.role === 'assistant' ? (
            <MessageLeft
              key={index}
              message={m.text}
              timestamp=''
              displayName='DOMA COACH'
            />
          ) : (
            <MessageRight
              key={index}
              message={m.text}
              timestamp=''
            />    
          ))
        }
        {request?.status === 'loading' && (
          <Box display="flex" justifyContent="flex-start">
            <TypingAnimation />
          </Box>
        )}
        {request?.program && (
          <Box display="flex" justifyContent="flex-start">
            <Button variant="contained" onClick={handleSeeProgram}> Ver entrenamiento </Button>
          </Box>
        )}

        {programDraft.program && (
          <Box>
            <TrainingProgramDetailsComponent program={programDraft.program} />
            <Box display="flex" py={2}>
                
                  <Button sx={{ flex: 1 }} fullWidth variant="contained" color="primary" disabled={true}>
                    Comenzar!
                  </Button>
                  <Button sx={{ flex: 1 }} fullWidth variant="text" color="secondary" onClick={handleTryAgain}>
                    No me convence
                  </Button>
              </Box>
            </Box>
        )}
      </Box>
      <Box sx={{ background: 'white', position: 'fixed', paddingBottom: '3px', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TextInput onSend={handleSendMessage} disabled={request.status === 'loading'}/>
      </Box>
    </Container>
  );
}
