import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Card, CardContent, Typography, CardActions, Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Box, Slide, List, Alert, SpeedDial, SpeedDialIcon } from '@mui/material';
import api, { ClientGoal, ClientHealth, Gym, User, WorkoutWeek } from '../../api';
import { useTranslation } from 'react-i18next';
import Transition from '../common/Transition';
import { GymCard } from '../profile/GymCard';
import { AssignmentOutlined, InfoOutlined, Widgets } from '@mui/icons-material';
import ReactDOM from 'react-dom';
import { ListItemAction } from '../Navbar';
import './effects.css';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CountUpDown from '../common/CountUpDown';
import GoalCard from '../client/goal-card';
import HealthCard from '../client/health-card';
import LogoGradient from '../../icons/logoGradient';
import { UserContext } from '../../contexts/userContext';

interface ClientViewTutorialProps {
  gym?: Gym;
  first?: boolean | null;
}


const ClientViewTutorial: React.FC<ClientViewTutorialProps> = ({ gym, first }) => {
  const [step, setStep] = React.useState('initial');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getUser } = useContext(UserContext);
  const handleFinishTutorial = async () => {
    if (!gym) { return; }
    setStep('initial');
    await api.gyms.setTutorialStep(gym.id, 'firstCustomer');
    getUser();
  }
  useEffect(() => {
    if (gym?.id && !gym.tutorialSteps?.firstCustomer ) {
      setStep('start');
    }
    if (first) setStep('initial')

  }, [gym, first]);

  const goalExample: ClientGoal = {
    workouts: [],
    id: 0,
    trainingPreferences: t('Tutorial.clientViewGoalTrainingPreferences'),
    mainGoal: t('Tutorial.clientViewGoalMainGoal'),
    availableDays: t('Tutorial.clientViewGoalAvailableDays'),
    trainingDuration: t('Tutorial.clientViewGoalTrainingDuration'),
    clientId: 0,
    createdAt: new Date()
  }
  const healthExample: ClientHealth = {
    id: 0,
    age: 20,
    weight: 70,
    height: 180,
    basal: 60,
    max: 200,
    clientId: 0,
    createdAt: new Date(),
    injuries: t('Tutorial.clientViewHealthInjuries'),
    previousExperience: t('Tutorial.clientViewHealthPreviousExperience'),
  };

  return (
    <>
      {step !== 'initial' && (
        <Backdrop open sx={{ zIndex: 10000 }}>
          {step === 'start' && (
            <>
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.clientViewGoalTitle')}</DialogTitle>
                <DialogContent>
                  <Box>
                    {t('Tutorial.clientViewGoalDescription')}
                  </Box>
                  <GoalCard goal={goalExample} />

                  <Box mt={1}>
                    {t('Tutorial.clientViewGoalDescription2')}
                  </Box>
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={() => setStep('health')}>
                    {t('Tutorial.next')}
                  </Button>

                  <Button onClick={handleFinishTutorial}>
                    {t('Tutorial.skip')}
                  </Button>
                </DialogActions>
              </Dialog>
          </>
          )}
           {step === 'health' && (
            <>
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.clientViewHealthTitle')}</DialogTitle>
                <DialogContent>
                  <Box>
                    {t('Tutorial.clientViewHealthDescription')}
                  </Box>
                  <HealthCard health={healthExample} />
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={() => setStep('generate')}>
                    {t('Tutorial.next')}
                  </Button>

                  <Button onClick={handleFinishTutorial}>
                    {t('Tutorial.skip')}
                  </Button>
                </DialogActions>
              </Dialog>
          </>
          )}

          {step === 'generate' && (
            <>
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.clientViewGenerateTitle')}</DialogTitle>
                <DialogContent>
                  <Box>
                    {t('Tutorial.clientViewGenerateDescription')}
                  </Box>

                  <Box>
                    {t('Tutorial.clientViewGenerateDescription2')}
                  </Box>
                  <Box mt={3} width="100%" textAlign="center" mb={3}>
                    <Button variant="outlined">
                      <LogoGradient />
                      <Typography variant="h6">{t('ClientView.newPlanification')}</Typography>
                    </Button>
                  </Box>
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={handleFinishTutorial}>
                    {t('Tutorial.understood')}
                  </Button>
                </DialogActions>
              </Dialog>
          </>
          )}
         </Backdrop>
      )}
    </>
  );
};

export default ClientViewTutorial;