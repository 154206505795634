import React, { ReactElement, FC, useEffect, useContext, useState } from "react";
import { Box } from "@mui/material";
import api, { Conversation, Program, Room, TrainingSession, WorkoutWeek, WorkoutWeekCreate, WorkoutWeekDraft } from "../api";
import RoomsList from "../components/RoomsList";
import ConverstationComponent from "../components/chat/Conversation";
import { UserContext } from "../contexts/userContext";
import ConversationProgram from "../components/chat/ConversationProgram";
import ConversationWorkoutWeekComponent from "../components/chat/ConversationWorkoutWeek";
import { useNavigate } from "react-router-dom";
import ConversationReviewComponent from "../components/chat/ConversationReview";

type ContainerProps = {
  threadId: string,
};


type RequestState = {
  status: string,
  conversation?: Conversation,
}


const ConversationThreadContainer: FC<ContainerProps> = ({ threadId }): ReactElement => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState<any>([]);
  const [request, setRequest] = useState<RequestState>({ status: 'initial' })
  const { user } = useContext(UserContext);
  
  const getThreadAndMessages = async (threadId: string) => {
    setRequest({ status: 'loading'});
    const res = await api.conversations.getOne(threadId);
    if (res.success) {
      setRequest({ conversation: res.data, status: 'success'});
    }else {
      setRequest({ status: 'error' });
    }
  }
  
  useEffect(() => {
    getThreadAndMessages(threadId);
  }, [threadId]);

  return (
    <ConversationReviewComponent conversation={request.conversation} />
  );
};

export default ConversationThreadContainer;