import React, { ReactElement, FC, useEffect, useState, useRef, RefObject, useContext } from "react";
import { Box, Button,  List, ListItemButton, ListItemAvatar, ListItemText, Typography, Alert, ListItem, ListItemIcon } from "@mui/material";
import api, { TrainingClass, TrainingSession } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import * as d3 from 'd3';
import { AccessTime, ClassOutlined, Favorite, LineAxis, MonitorHeart, RecordVoiceOver, Scoreboard } from "@mui/icons-material";
import Chart, { DataPoint, LineChartProps } from "../components/EffortLineChart";
import HeartRateContainer from "../containers/HeartRateContainer";
import TrainingSessionDataContainer from "../containers/TrainingSessionDataContainer";
import { UserContext } from "../contexts/userContext";
import { formattedTime } from "../utils/times";
import dayjs from "dayjs";
import { PageTitle } from "../components/PageTitle";
import TrainingProgramDetailsComponent from "../components/TrainingDetails";
import { CalendarIcon } from "@mui/x-date-pickers";

type TrainingSessionState = {
  data?: TrainingSession,
  status: string,
  error?: string | boolean,
}

/**
 * A training session in progress
 */
const TrainingSessionPage: FC<any> = (): ReactElement => {
  const containerRef = useRef<any>();
  const navigate = useNavigate();
  const chartRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [trainingSession, setTrainingSession] = useState<TrainingSessionState>({ status: 'initial' });
  const [trainingData, setTrainingData] = useState({});
  const [waveLock, setWaveLock] = useState(null);
  const [chartData, setChartData] = useState<LineChartProps>({ width: 0, height: 0, xScale: d3.scaleLinear(), yScale: d3.scaleLinear(), points: [], realPoints: [] });
  
  const { sessionId = '' } = useParams();
  const { user } = useContext(UserContext);
  
  const handleOnChartRef = (ref: HTMLDivElement) => chartRef.current = ref;
  const handleQuitSession = async () => {
    if (!trainingSession.data) return;

    const res: any = await api.trainingSessions.end(trainingSession.data?.id);
    if (!res.success) {
      setTrainingSession((values) => ({
        ...values,
        error: res.error,
      }))
    } else {
      window.location.reload();
    }
  }

  const handleStartSession = async () => {
    const res = await api.trainingSessions.start(`${trainingSession.data?.id}`);

    if (res.success) {
      window.location.reload();
      setTrainingData(res.data);
      setTrainingSession((values) => ({
        ...values,
        data: res.data,
      }))
    } else {
      // error
    }

  }

  const getTrainingData = async (training: TrainingSession) => {
    const res = await api.trainingSessions.data(training.id, training.tClass.roomId, training.band.code);
    if (res.success) {
      setTrainingData(res.data);
    }
  }

  const getCurrentTrainingSession = async (id: string) => {
    setTrainingSession({ status: "loading" })
    // TODO: improve this by sala
    const tsResponse = await api.trainingSessions.get(id);
    if (tsResponse.success && tsResponse.data) {
      const data: TrainingSession = tsResponse.data;
      // TECHDEBT: this is duplicated from pages/TrainingClass.tsx
      const points: DataPoint[] = data?.tClass?.program?.steps.reduce((acc: any[], curr) => {
        for (let i = 0; i < curr.duration; i++) {
          acc.push(curr.efforts);
        }
        return acc;
      }, []).map((p: any, index: number) => ({ x: index, y: p })) || [];

      // this is exclusive for training session
      const realPoints: DataPoint[] = data?.currentEffort.map((p: any, index: number) => ({ x: index, y: p })) || [];

      const width = containerRef?.current?.getBoundingClientRect().width;
      const height = containerRef?.current?.getBoundingClientRect().width/1.6;
      const xScale = d3.scaleLinear()
        .domain([0, points.length])
        .range([0, width])
        .clamp(true);
      
      const yScale = d3.scaleLinear()
        .domain([0, 100])
        .range([height, 0])
        .clamp(true);
      
      setChartData({
        points,
        realPoints,
        width,
        height,
        xScale,
        yScale,
      });
      console.log('training sessin page', yScale(0), yScale(50), yScale(90), yScale(150))
      setTrainingSession({ data, status: "success" });
      
      if (data.status === 'IN_PROGRESS') {
        getTrainingData(data);
      }
    } else {
      setTrainingSession({ status: "failed", error: tsResponse.error })
    }
  }

  const requestWakeLock = async () => {
    try {
      const wakeLock = (await navigator as any).wakeLock?.request('screen');
  
      wakeLock.addEventListener('release', () => {
        console.log('Wake Lock was released');
      });
      console.log('Wake Lock is active');
      setWaveLock(waveLock);
    }
    catch(err: any) {
      console.error(`${err.name}, ${err.message}`);
    }
  };
  
  const releaseWakeLock = () => {
    console.log('releasing wakeLock');
    if (waveLock && (waveLock as any).release) {
      (waveLock as any).release();
      setWaveLock(null);
    }
  };

  useEffect(() => {
    getCurrentTrainingSession(sessionId);
    requestWakeLock();
    return releaseWakeLock;
  }, []);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
      sx={{ paddingBottom: 8 }}
    >
      <PageTitle title={trainingSession?.data?.tClass?.name} />
      <Box position="relative">
        {chartData.points.length && (<Chart
          points={chartData.points}
          realPoints={chartData.realPoints}
          width={chartData.width}
          height={chartData.height}
          xScale={chartData.xScale}
          yScale={chartData.yScale}
          onChartRef={handleOnChartRef}
        />)}
        {trainingSession.data?.status !== 'FINISHED' ? (
          <HeartRateContainer chartRef={chartRef} xScale={chartData.xScale} yScale={chartData.yScale} sessionId={sessionId} roomId={`${trainingSession.data?.tClass.roomId}`} bandCode={trainingSession.data?.band.code} />
        ) : (
          <></>
        )}
        </Box>
        <Box mt={2} display="flex">
          {trainingSession.data?.status === 'FINISHED' ? (
            <List>
              <ListItem>
                <ListItemIcon title="Fecha"><CalendarIcon /></ListItemIcon>
                <ListItemText>{dayjs(trainingSession.data.createdAt).format('DD/MM/YYYY HH:mm')}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon title="Esfuerzo"><LineAxis /></ListItemIcon>
                <ListItemText>{trainingSession.data.avgEffort.toFixed(1)}%</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon title="Puntos"><Scoreboard /></ListItemIcon>
                <ListItemText>{trainingSession.data.points.toFixed(1)}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon title="Pulsaciones"><Favorite /></ListItemIcon>
                <ListItemText>
                  {trainingSession.data.currentData?.length ? (
                    Math.round(trainingSession.data.currentData.reduce((acc, curr) => acc + curr, 0) / trainingSession.data.currentData.length)
                  ) : (
                    "-"
                  )}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon title="Tiempo de entrenamiento"><AccessTime /></ListItemIcon>
                <ListItemText>{formattedTime(trainingSession.data.currentData.length)}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon title="Profesor"><RecordVoiceOver /></ListItemIcon>
                <ListItemText>{trainingSession.data.professor?.user.name}</ListItemText>
              </ListItem>
            </List>
          ) : (
            <Box flex="1">
              <TrainingSessionDataContainer />
            </Box>
          )}
          <Box flex="1">
            <List>
              {trainingSession.data?.tClass?.program.steps.map((step) => (
                <ListItem key={step.id} sx={{ textAlign: "right" }}>
                  <ListItemText
                    primary={step.name}
                    secondary={`${formattedTime(step.duration)} al ${step.efforts}%`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        {trainingSession.data?.status === 'PENDING' && (
          <Button variant="contained" color="primary" onClick={handleStartSession}>
            Comenzar
          </Button>
        )}
        {trainingSession.data?.status === 'IN_PROGRESS' && (
          <Button variant="contained" color="primary" onClick={handleQuitSession}>
            Finalizar
          </Button>
        )}
        {trainingSession.error && (
          <Alert severity="error">{trainingSession.error}</Alert>
        )}
      </Box>
    </Box>
  );
};

export default TrainingSessionPage;