import React, { ReactElement, FC, useEffect, useState, useContext } from "react";
import { Box, Card, CardContent, CardMedia, Typography, Grid, CircularProgress, CardActions, Button, Skeleton } from "@mui/material";
import api, { Product } from "../api"; // Assume you have an API module to fetch Stripe products
import { useTranslation } from "react-i18next";
import { PageTitle } from "../components/PageTitle";
import { UserContext } from "../contexts/userContext";
import SkeletonComponent from "../components/common/SkeletonLoading";
import { PRState } from "./ProfilePage";

const PackagesPage: FC<any> = (): ReactElement => {
  const [products, setProducts] = useState<Array<Product>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [planRequest, setPlanRequest] =  useState<PRState>({ status: 'loading', error: '' });
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const fetchProducts = async () => {
	try {
	  const res = await api.stripe.products();
	  if (res.success) {
		setProducts(res.data.sort((a: Product, b: Product) => a.prices[0].amount - b.prices[0].amount));
	  }
	} catch (error) {
	  console.error("Error fetching products:", error);
	} finally {
	  setLoading(false);
	}
  };

  /** duplicated from ProfilePage */
  const getPlan = async (id: number) => {
    if (!user?.id) { return; }
    setPlanRequest({ status: 'loading', error: '' })
    const res = await api.users.getSubscriptions(user.id);
    if (res.success) {
      if (res.data?.length) {
        setPlanRequest({ plan: res.data[0], status: 'success', error: '' })
      } else {
        setPlanRequest({ plan: undefined, status: 'success', error: '' })
      }
    } else {
      setPlanRequest({ plan: undefined, status: 'error', error: res.error })
    }
  }

  const handleLinkPortal = async () => {
    if (!user?.id) { return; }
    const id = user.id;
    const res = await api.users.customerPortalLink(id);
    console.log('resss', res)
    if (res.success && res.data) {
      window.location.href = res.data.url;
    }
  }
  /** EndOf duplicated from ProfilePage */

  const clickBuy = (priceId: string) => async () => {
    if (!user.id) return;
    setPaymentLoading(true);
    const res = await api.stripe.createCheckout(user.id, priceId);
    if (res.success) {
      setPaymentLoading(false);
      window.location.href = res.data.checkout;
    }
  }


  const clickSubscribe = (priceId: string) => async () => {
    if (!user.id) return;
    setPaymentLoading(true);
    const res = await api.stripe.createCheckoutSubscription(user.id, priceId);
    if (res.success) {
      setPaymentLoading(false);
      window.location.href = res.data.checkout;
    }
  }
    
  useEffect(() => {
  	fetchProducts();
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (user?.id) {
      getPlan(user.id);
    }
  }, [user?.id]);

  return (
	<Box
	  display={"flex"}
	  flexDirection={"column"}
	  padding={2}
	>
    <PageTitle title={t('PackagesPage.title')} />
    <Typography variant="h3" color="text.primary" sx={{ mb: 2, mt: 3, textAlign: 'left' }}>
      {t('PackagesPage.plans')}

      <a href="https://wizfit.ai/inicio/#precios" target="_blank" rel="noreferrer">
        <Typography variant="body1" color="text.secondary" sx={{ ml: 2, textAlign: 'left' }} component="span">
          {t('PackagesPage.moreInfo')}
        </Typography>
      </a>
    </Typography>
    {loading ? (
       <Box gap={2} display="flex" flexDirection={['column', 'row']}>
         <Skeleton variant="rectangular" width={350} height={350}  animation="wave" />
         <Skeleton variant="rectangular" width={350} height={350} animation="wave"/>
         <Skeleton variant="rectangular" width={350} height={350} animation="wave"/>
       </Box> 
    ) : (
      <Grid container spacing={2}>
      {products.filter(p => p.subscribable).map((product: Product) => (
        <Grid item xs={12} sm={6} md={4} key={product.id}>
        <Card>
          <CardMedia
          component="img"
          height="140"
          image={product.images[0] || '/logo_2_out.png'}
          alt={product.name}

          sx={{ objectFit: 'contain', my: 1 }}
          />
          <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {product.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
            {t('PackagesPage.creditsPerMonth', { credits: product.metadata['planifications'] })}
          </Typography>
          <Typography variant="h6" color="text.primary">
            ${product.prices[0].amount / 100} ({product.prices[0].currency}) {` / ${t('PackagesPage.month')}`}
          </Typography>
          </CardContent>
          <CardActions>
            {planRequest.plan?.active ? (
              <Button disabled={paymentLoading} fullWidth variant="contained" sx={{ borderRadius: 0 }} size="large" color="primary" onClick={handleLinkPortal}>
                {t('PackagesPage.update')}
              </Button>
            ) : (
              <Button disabled={paymentLoading} fullWidth variant="contained" sx={{ borderRadius: 0 }} size="large" color="primary" onClick={clickSubscribe(product.prices[0].id)}>
                {t('PackagesPage.subscribe')}
              </Button>
            )}
          </CardActions>
        </Card>
        </Grid>
      ))}
      </Grid>
    )}
    <Typography variant="h3" color="text.primary" sx={{ my: 2, textAlign: 'left' }}>
      {t('PackagesPage.prepaid')}
    </Typography>
    {loading ? (
       <Box gap={2} display="flex" flexDirection={['column', 'row']}>
         <Skeleton variant="rectangular" width={350} height={350}  animation="wave" />
         <Skeleton variant="rectangular" width={350} height={350} animation="wave"/>
         <Skeleton variant="rectangular" width={350} height={350} animation="wave"/>
       </Box> 
    ) : (
      <Grid container spacing={2}>
      {products.filter(p => !p.subscribable).map((product: Product) => (
        <Grid item xs={12} sm={6} md={4} key={product.id}>
        <Card>
          <CardMedia
          component="img"
          height="140"
          image={product.images[0]}
          alt={product.name}
          sx={{ my: 1}}
          />
          <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {product.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ my: 1 }}>
            {product.description}
          </Typography>
          <Typography variant="h6" color="text.primary">
            ${product.prices[0].amount / 100} ({product.prices[0].currency})
          </Typography>
          </CardContent>
          <CardActions>
            <Button disabled={paymentLoading} fullWidth size="large" color="primary" onClick={clickBuy(product.prices[0].id)}>
              {t('PackagesPage.buy')}
            </Button>
          </CardActions>
        </Card>
        </Grid>
      ))}
      </Grid>
    )}
    
	</Box>
  );
};

export default PackagesPage;