import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, CardMedia, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const EditExerciseCardTutorial = () => {
  const { t } = useTranslation();
  return (

    <Card >
      <CardHeader title={t('Tutorial.editPlanificationReadyExerciseName')} action={(
        <IconButton>
          <DeleteOutline />
        </IconButton>
      )} />
      <CardContent>
        <Box display="flex">
          <Box display="flex" flexDirection="column" flex="2" alignItems="center">
            <CardMedia component="img" image="/logo_2.png" alt=".." width={60} sx={{ opacity: 0.6, width: '70px', mb: 2 }} />
            <caption>{t('Tutorial.editPlanificationReadyExerciseMachine')}</caption>
          </Box>
          <Box flex="8" ml={2}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('WorkoutweekEditor.series')}</TableCell>
                    <TableCell>{t('WorkoutweekEditor.reps')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                      <TableCell>1</TableCell>
                      <TableCell>
                        <span style={{ width: 70 }}>{10}</span>
                      </TableCell>
                      <TableCell>
                        <IconButton>
                          <EditOutlined />
                        </IconButton>
                        <IconButton>
                          <DeleteOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Skeleton variant="rectangular" width={"100%"} height={50} />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      
                      <TableCell colSpan={3}>
                        <Skeleton variant="rectangular" width={"100%"} height={50} />
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </CardContent>
    </Card>
)};
