import React, { ReactElement, FC, useEffect, useContext, useState } from "react";
import { Box } from "@mui/material";
import api, { TrainingSession } from "../api";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import TrainingsList from "../components/TrainingsList";

type TrainingsState = {
  data?: TrainingSession[],
  status: string,
  error?: string,
}
type ContainerProps = {
  clientId?: number,
};

const TrainingsListContainer: FC<ContainerProps> = ({ clientId }): ReactElement => {
  const [trainings, setTrainings] = useState<TrainingsState>({ data: [], status: 'initial' });

  const getTrainings = async (clientId: number) => {
    setTrainings({ data: [], status: 'loading' });
    const res = await api.clients.getTrainings(clientId)

    if (res.success) {
      setTrainings({ data: res.data, status: 'success' });
    }else {
      setTrainings({ data: [], status: 'error', error: res.error })
    }
  }

  useEffect(() => {
    if (clientId) {
      getTrainings(clientId);
    }
  }, [clientId]);

  return (
    <TrainingsList data={trainings.data} status={trainings.status} error={trainings.error} />
  );
};

export default TrainingsListContainer;