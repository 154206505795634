import React from 'react';

const Checked = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4371 0.0646973C6.35278 0.0646973 0.603149 5.81433 0.603149 12.8987C0.603149 19.9831 6.35278 25.7327 13.4371 25.7327C20.5215 25.7327 26.2711 19.9831 26.2711 12.8987C26.2711 5.81433 20.5215 0.0646973 13.4371 0.0646973ZM10.8703 19.3157L4.45335 12.8987L6.26294 11.0891L10.8703 15.6837L20.6113 5.94267L22.4209 7.76509L10.8703 19.3157Z" fill="#9B72FE"/>
    </svg>
  );
};

export default Checked;
