import React, { useState } from 'react';
import { TrainingSession } from '../api';

const INITIAL_TRAINING_SESSION: TrainingSession = {
  id: 0,
  name: '',
  professorId: 0,
  bandId: 0,
  tClassId: 0,
  status: '',
  currentData: [],
  currentEffort: [],
  calories: 0,
  points: 0,
  avgEffort: 0,
  autologout: false,
  createdAt: new Date(),
  clientId: 0,
  clientHealthId: 0,
  band: {
    id: 0,
    code: ''
  },
  tClass: {
    id: 0,
    name: '',
    startTime: new Date(),
    duration: 0,
    professorId: 0,
    programId: 0,
    roomId: 0,
    hidden: false,
    program: {
      id: 0,
      name: '',
      description: '',
      authorId: 0,
      author: {id: 0, pin: '1234', userId: 0,user: {
        name: '',
        lastname: '',
        email: '',
        gender: '',
      } },
      steps: []
    },
    room: {
      avatar: '',
      code: '',
      name: '',
      id: 0,
      parent: '',
      status: '',
    },
    professor: {
      id: 0,
      userId: 0,
      pin: '',
      user: {
        name: '',
        lastname: '',
        email: '',
        gender: '',
      }
    }
  },
  client: {
    id: 0,
    nick: '',
    userId: 0,
    clientHealths: [],
    clientGoals: [],
    profession: '',
    bodyType: '',
  },
  clientHealth: {
    id: 0,
    age: 0,
    weight: 0,
    height: 0,
    basal: 0,
    max: 0,
    clientId: 0,
    createdAt: new Date(),
    injuries: '',
    previousExperience: ''
  },
  professor: {
    id: 0,
    userId: 0,
    pin: '',
    user: {
      name: '',
      lastname: '',
      email: '',
      gender: '',
    }
  }
};

export type TrainingData = {
  calories: number,
  currentData: number[],
  currentEffort: number[],
  points: number,
  rank: number,
  score: number,
};

const INITIAL_TRAINING_DATA: TrainingData = {
  calories: 0,
  currentData: [],
  currentEffort: [],
  points: 0,
  rank: 0,
  score: 0,
};

const SessionContext = React.createContext({
  trainingSession: INITIAL_TRAINING_SESSION,
  trainingData: INITIAL_TRAINING_DATA,
  updateTrainingSession: (V: TrainingSession) => {},
  updateTrainingData: (D: TrainingData) => {},
});

const SessionContextProvider = ({ children }: any) => {
  const [trainingSession, setTrainingSession] = useState<TrainingSession>(INITIAL_TRAINING_SESSION);
  const [trainingData, setTrainingData] = useState<TrainingData>(INITIAL_TRAINING_DATA);

  const updateTrainingSession = (newValue: TrainingSession) => {
    setTrainingSession(newValue);
  };

  const updateTrainingData = (newValue: TrainingData) => {
    setTrainingData(newValue);
  };

  return (
    <SessionContext.Provider value={{ trainingSession, updateTrainingSession, trainingData, updateTrainingData }}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionContextProvider };
