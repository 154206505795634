import React from 'react';
import { ReactElement, FC, useState, ChangeEvent, useEffect, useContext } from "react";
import { Container, useTheme } from "@mui/material";
import api, { Program, ProgramStep, Room, WorkoutWeekDraft } from "../api";
import { UserContext } from "../contexts/userContext";
import { useNavigate } from "react-router-dom";
import ModalConversation from '../components/chat/ModalConversation';
import ConversationContainer from '../containers/ConversationWorkoutWeeksContainer';
import { WizardContextProvider } from '../contexts/PlanifyWizardContext';
import Wizard from '../components/wizard/wizardPlanify';


const INITIAL_SESSION = { room: '', time: '', details: '' };

const WorkoutWeeksAddAiPage: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
console.log('user', user)
  useEffect(() => {
  }, [])


  return (
    <Container maxWidth="sm" sx={{ marginTop: `0px`, marginBottom: '70px', padding: 0, paddingLeft: 0, paddingRight: 0 }}>
      <WizardContextProvider>
        <Wizard user={user} />
      </WizardContextProvider>
    </Container>
  );
};

export default WorkoutWeeksAddAiPage;
