import React, { ReactElement, FC, useEffect, useState, useRef, useContext } from "react";
import { Box, Button,  List, ListItemButton, ListItemAvatar, ListItemText, Typography, Alert, ListItem, ListItemSecondaryAction } from "@mui/material";
import { Program, TrainingSession } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import * as d3 from 'd3';
import Chart, { DataPoint, LineChartProps } from "../components/EffortLineChart";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import { formattedTime } from "../utils/times";
import { getColorByEffort } from "../utils/colors";
import { useTranslation } from "react-i18next";

type ComponentProps = {
  program?: Program
};
type TrainingProgramState = {
  data?: TrainingSession,
  status: string,
}

const TrainingProgramDetailsComponent: FC<ComponentProps> = ({ program }): ReactElement => {
  const containerRef = useRef<any>();
  const [trainingProgram, setTrainingProgram] = useState<TrainingProgramState>({ status: "initial" });
  const [chartData, setChartData] = useState<LineChartProps>({ width: 0, height: 0, xScale: d3.scaleLinear(), yScale: d3.scaleLinear(), points: [] });

  const { t } = useTranslation();
  const calculateChart = async (program: Program) => {
    // TECHDEBT: this is duplicated from pages/TrainingSession.tsx
    const points: DataPoint[] = program.steps.reduce((acc: any[], curr) => {
      for (let i = 0; i < curr.duration; i++) {
        acc.push(curr.efforts);
      }
      return acc;
    }, []).map((p: any, index: number) => ({ x: index, y: p })) || [];

    const width = containerRef?.current?.getBoundingClientRect().width;
    const height = containerRef?.current?.getBoundingClientRect().width/1.6;
    const xScale = d3.scaleLinear()
      .domain([0, points.length])
      .range([0, width]);
    
    const yScale = d3.scaleLinear()
      .domain([0, 100])
      .range([height, 0]);
    
    setChartData({
      points,
      width,
      height,
      xScale,
      yScale,
    });
  };

  useEffect(() => {
    if (program) {
      calculateChart(program);
    }
  }, []);

  if (!program) {
    return <></>
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
    >
      <Box mb={2}>
        <Typography variant="h4">{program.name}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">{program.description}</Typography>
      </Box>
      {chartData.points.length && (<Chart
        points={chartData.points}
        width={chartData.width}
        height={chartData.height}
        xScale={chartData.xScale}
        yScale={chartData.yScale}
      />)}
      <Box display="flex" mt={2} flexDirection="column">
        <Typography variant="h6">{t('TrainingDetails.stages')}</Typography>
        <List>
          {program.steps.map((step) => (
            <ListItem key={step.id}>
              <ListItemText
                primary={`${step.name} (${formattedTime(step.duration).trim()})`}
                secondary={step.description}
              />
              <ListItemSecondaryAction>
                <Typography variant="subtitle1" color={getColorByEffort(step.efforts)}>
                  {`${step.efforts}%`}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default TrainingProgramDetailsComponent;