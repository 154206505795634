import React, { useState } from 'react';
import ClientGoalAndPreferences from '../common/ClientGoalAndPreference';
import { Alert, Box, Button, DialogActions, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { HeightOutlined, MonitorWeightOutlined, PhoneOutlined, WorkOutline } from '@mui/icons-material';
import { Client } from '../../api';
import { CalendarIcon, DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import Endomorph from '../../icons/endomorph';
import Mesomorph from '../../icons/mesomorph';
import Ectomorph from '../../icons/ectomorph';
import { useTranslation } from 'react-i18next';

export type ClientUpdate = {
  nick: string,
  name: string,
  lastname: string,
  birthdate: Date,
  bodyType: string,
  profession: string,
  gender: string,
  phone: string,
  email: string,
  document: string,
};

type Props = {
  onSave: (data: ClientUpdate) => void;
  request: { status: string, error: string };
  onCancel: () => void;
  client?: Client;
};


const EditForm: React.FC<Props> = ({ onSave, request, client, onCancel }: Props) => {
  const [data, setData] = React.useState<ClientUpdate>({
    nick: client?.nick || '',
    name: client?.user?.name || '',
    lastname: client?.user?.lastname || '',
    birthdate: dayjs(client?.user?.birthdate).toDate(),
    bodyType: client?.bodyType || '',
    profession: client?.profession || '',
    gender: client?.user?.gender || '',
    phone: client?.user?.phone || '',
    document: client?.user?.document || '',
    email: client?.user?.email || '',
  });

  const { t } = useTranslation();

  const handleDataChange = (field: string) => (e: { target: { value: any; }; }) => {
    setData({ ...data, [field]: e.target.value });
  }

  const handleBodyTypeChange = (bodyType: string) => setData({ ...data, bodyType });
  const handleDateChange = (value: Dayjs | null, context: any) => {
    if (!value)  return;
    console.log(value?.toDate())
    setData({ ...data, birthdate: value.toDate() })
  };

  const handleSave = () => {
    onSave(data);
  };

  return (
    <Box width="100%" px={[2, 4]} py={[1,2]} display="flex" flexDirection="column">
      <Box display="flex" gap={2} mb={2} flexDirection={['column', 'row']}>
        <TextField
          sx={{ flex: 1}}
          label={t('ClientEdit.email')}
          value={data.email}
          disabled={client?.id !== 0}
          onChange={handleDataChange('email')}
        />
        <TextField
          sx={{ flex: 1}}
          label={t('ClientEdit.document')}
          value={data.document}
          onChange={handleDataChange('document')}
        />
      </Box>
      <Box display="flex" flexDirection={['column', 'row']} mt={2} gap={2}>
        <TextField
          sx={{ flex: 1}}
          label={t('ClientEdit.names')}
          value={data.name}
          onChange={handleDataChange('name')}
        />

        <TextField
          label={t('ClientEdit.lastname')}
          sx={{ flex: 1}}
          value={data.lastname}
          onChange={handleDataChange('lastname')}
        />
        <TextField
          sx={{ flex: 1}}
          label={t('ClientEdit.nick')}
          value={data.nick}
          onChange={handleDataChange('nick')}
        />
      </Box>
      <Box display="flex" flexDirection={['column', 'row']} mt={2} gap={2}>

        <TextField
          sx={{ flex: 1}}
          label={t('ClientEdit.profession')}
          value={data.profession}
          onChange={handleDataChange('profession')}
          InputProps={{
            startAdornment: <WorkOutline sx={{ mr: 1 }}/>,
          }}
        />
        <TextField
          sx={{ flex: 1}}
          label={t('ClientEdit.phone')}
          value={data.phone}
          onChange={handleDataChange('phone')}
          InputProps={{
            startAdornment: <PhoneOutlined sx={{ mr: 1 }}/>,
          }}
        />
        <DatePicker label={t('ClientEdit.birthdate')} value={dayjs(data.birthdate)} onChange={handleDateChange} />

      </Box>
      <Box mb={2} mt={2}>
        <Typography variant="subtitle1" color="grey">Sexo</Typography>
        <FormControl component="fieldset">
          <RadioGroup row aria-label="gender" name="gender" value={data.gender} onChange={handleDataChange('gender')}>
            <FormControlLabel value="MASCULINE" control={<Radio />} label={t('ClientEdit.maleBody')} />
            <FormControlLabel value="FEMENINE" control={<Radio />} label={t('ClientEdit.femaleBody')} />
            <FormControlLabel value="UNDEFINED" control={<Radio />} label={t('ClientEdit.unefinedBody')} />
          </RadioGroup>
        </FormControl>
        <Typography variant="subtitle1" color="grey" sx={{ marginTop: 3 }}>Tipo de cuerpo</Typography>
        
        <Box display="flex" justifyContent="space-around" mt={2} gap={1} flexDirection={['column', 'row']}>
          <Box onClick={() => handleBodyTypeChange('endomorph')} className={`body-type ${data.bodyType === 'endomorph' ? 'selected' : ''}`} flex="1" sx={{ height: 360, cursor: 'pointer' }}>
            <Endomorph selected={data.bodyType === 'endomorph'} gender={data.gender} />
          </Box>
          <Box onClick={() => handleBodyTypeChange('mesomorph')} className={`body-type ${data.bodyType === 'mesomorph' ? 'selected' : ''}`} flex="1" sx={{ height: 360, cursor: 'pointer' }}>
            <Mesomorph selected={data.bodyType === 'mesomorph'} gender={data.gender} />
          </Box>
          <Box onClick={() => handleBodyTypeChange('ectomorph')} className={`body-type ${data.bodyType === 'ectomorph' ? 'selected' : ''}`} flex="1" sx={{ height: 360, cursor: 'pointer' }}>
            <Ectomorph selected={data.bodyType === 'ectomorph'} gender={data.gender} />
          </Box>
        </Box>
      </Box>
      {request.status === 'error' && (
        request.error?.indexOf('document') > 0 ? (
          <Alert sx={{ my: 2}} severity="error">{t('ClientEdit.documentExists')}</Alert>
        ) : (
          <Alert sx={{ my: 2}} severity="error">{t('ClientEdit.wasErrorCompleteData')}</Alert>
        )
      )}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={request.status === 'loading'}>
        {client?.id !== 0 ? t('ClientEdit.update') : t('ClientEdit.create')}
        </Button>
        <Button variant="text" color="primary" onClick={onCancel} disabled={request.status === 'loading'}>
        {client?.id !== 0 ? t('ClientEdit.cancel') : t('ClientEdit.back')}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default EditForm;