import React from 'react';
import { ReactElement, FC, useState, ChangeEvent, useEffect, useContext } from "react";
import { Alert, AlertTitle, AppBar, Avatar, Box, Button, CircularProgress, Dialog, Divider, FormControl, IconButton, InputAdornment, InputLabel, Modal, NativeSelect, Popover, TextField, Toolbar, Typography, useTheme } from "@mui/material";
import ConversationContainer from "../../containers/ConversationContainer";
import { useNavigate } from "react-router-dom";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ElevationScroll from '../../components/common/ElevationScroll';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const ModalConversation: FC<any> = ({ title = 'DOMA Coach', avatarUrl = '/domagym.jpg', Container = ConversationContainer, onClose }): ReactElement => {
  const theme = useTheme();

  return (
    <Dialog
      fullScreen
      open={true}
      TransitionComponent={Transition}
    >
      <ElevationScroll>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <Close />
            </IconButton>

            <Avatar
              alt={title}
              sx={{
                width: theme.spacing(4),
                height: theme.spacing(4)
              }}
              src={avatarUrl}
            ></Avatar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        
      </ElevationScroll>
      
        <Box display="flex" flexDirection="column" p={1}  sx={{ background: 'white' }}>
          <Container />
        </Box>
    </Dialog>
  );
};

export default ModalConversation;
