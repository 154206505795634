import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Card, CardContent, Typography, CardActions, Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Box, Slide, List, Alert } from '@mui/material';
import api, { Gym, User } from '../../api';
import { useTranslation } from 'react-i18next';
import Transition from '../common/Transition';
import { GymCard } from '../profile/GymCard';
import { AssignmentOutlined, InfoOutlined, Widgets } from '@mui/icons-material';
import ReactDOM from 'react-dom';
import { ListItemAction } from '../Navbar';
import './effects.css';
import { UserContext } from '../../contexts/userContext';

interface SignupTutorialProps {
  user: User;
  gym?: Gym;
  creditsRef?: React.RefObject<HTMLDivElement>;
  logoRef?: React.RefObject<HTMLDivElement>;
}

const SignupTutorial: React.FC<SignupTutorialProps> = ({ user, gym, creditsRef, logoRef }) => {
  const [step, setStep] = React.useState('initial');
  const { t } = useTranslation();
  const bounds = creditsRef?.current?.getBoundingClientRect();
  const boundsLogo = logoRef?.current?.getBoundingClientRect();
  const [planifications, setPlanificagions] = useState<HTMLElement | null>();

  const { getUser } = useContext(UserContext);

  const handleClickList = () => {
    handleFinishTutorial();
    // not proud of this
    const backdrops = document.getElementsByClassName('MuiBackdrop-root') as unknown as any[];
    for (let i = 0; i < backdrops.length; i++) {
      backdrops[i].click();
    }
  };
  const handleFinishTutorial = async () => {
    if (!gym) { return; }
    setStep('initial');
    await api.gyms.setTutorialStep(gym.id, 'signup');
    getUser();
  }
  useEffect(() => {
    if (!gym?.tutorialSteps?.signup) {
      setStep('start');
    }
  }, []);
  useEffect(() => {
    if (step === 'navbar') {
      document.getElementById('navbar-burger')?.click();
      setTimeout(() => {
        const element = document.getElementById('navbar-planifications');
        setPlanificagions(element);
      }, 500);
    }
  }, [step])
  
  return (
    <>
      {step !== 'initial' && (
        <Backdrop open sx={{ zIndex: 10000 }}>
          {step === 'start' && (
            <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
              <DialogTitle>{t('Tutorial.firstSignInTitle')}</DialogTitle>
              <DialogContent>
                {t('Tutorial.firstSignInDescription')}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setStep('image')}>
                  {t('Tutorial.firstSignInButton')}
                </Button>
                <Button onClick={handleFinishTutorial}>
                  {t('Tutorial.skip')}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {step === 'image' && boundsLogo && gym && (
            <>
              <GymCard gym={gym} sx={{ position: 'absolute', top: boundsLogo?.y, left: boundsLogo.x, height: boundsLogo.height, width: boundsLogo.width  }}/>
              <Slide direction="up" in={step === 'image'} mountOnEnter unmountOnExit>
                <Paper sx={{ zIndex: 10001, position: 'absolute', bottom: [0, 'unset'], top: ['unset', boundsLogo.y + boundsLogo.height + 20] } }>
                  <DialogTitle>{t('Tutorial.imageTitle')}</DialogTitle>
                  <DialogContent>
                    <Typography variant="body1">
                      {t('Tutorial.imageDescription1')}
                    </Typography>
                    <Typography variant="body1">
                      {t('Tutorial.imageDescription2')}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setStep('credits')}>
                      {t('Tutorial.next')}
                    </Button>
                    <Button onClick={handleFinishTutorial}>
                      {t('Tutorial.skip')}
                    </Button>
                  </DialogActions>
                </Paper>
              </Slide>
            </>
          )}
          {step === 'credits' && bounds && (
            <>
              <Card sx={{ position: 'absolute', top: bounds?.y, left: bounds?.x, width: bounds.width }}>
                <CardContent>
                  <Typography variant="h6">{t('Profile.planificationsRemaining')}</Typography>
                  <Typography variant="h1" fontWeight={'bold'} mt={4} sx={{ textAlign: 'center' }}>
                    {'X'}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">
                    {t('Profile.seePackages')}
                  </Button>
                </CardActions>
              </Card>
              <Slide direction="up" in={step === 'credits'} mountOnEnter unmountOnExit>
                <Paper sx={{ zIndex: 10001, position: 'absolute', top: [0, bounds?.y + bounds.height + 20] } }>
                  <DialogTitle>{t('Tutorial.credits')}</DialogTitle>
                  <DialogContent>
                    {t('Tutorial.creditsDescription')}
                    <Box mt={2}>
                      <Button size="small">
                        {t('Profile.seePackages')}
                      </Button>
                      {t('Tutorial.creditsUp')}
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setStep('navbar')}>
                      {t('Tutorial.next')}
                    </Button>
                    <Button onClick={handleFinishTutorial}>
                      {t('Tutorial.skip')}
                    </Button>
                  </DialogActions>
                </Paper>
              </Slide>
            </>
          )}

          {step === 'navbar' && planifications && (
            <>
              <Box sx={{ 
                position: 'absolute',
                top: planifications.getBoundingClientRect().y - 5,
                left: planifications.getBoundingClientRect().x,
                width: planifications.getBoundingClientRect().width,

                height: planifications.getBoundingClientRect().height,
              }}>
                <List sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' }, height: 50 }} className="glow-effect" onClick={handleClickList}>
                  <ListItemAction label={t('Navbar.planifications')} Icon={<AssignmentOutlined />} to="/workoutweeks"/>
                </List>
              </Box>
              <Slide direction="up" in={step === 'navbar'} mountOnEnter unmountOnExit>
                <Paper sx={{ zIndex: 10003, position: 'absolute', bottom: [0, 'unset'] }}>
                  <DialogTitle>{t('Tutorial.navbar')}</DialogTitle>
                  <DialogContent>
                    {t('Tutorial.navbarDescription')}
                    <Typography variant="body1">
                      {t('Tutorial.navbarDescription2')}
                      <Typography variant="subtitle1" fontWeight="bold" component="span" sx={{ ml: 1}}>
                        {t('Tutorial.navbarDescriptionReusable')}
                      </Typography>
                    </Typography>

                    <Alert severity="info">
                      {t('Tutorial.navbarDescriptionReusable2')}
                    </Alert>
                    <Typography variant="body1" sx={{ mt: 2}}>
                      {t('Tutorial.navbarDescription3')}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleFinishTutorial}>
                      {t('Tutorial.skip')}
                    </Button>
                  </DialogActions>
                </Paper>
              </Slide>
            </>
          )}
        </Backdrop>
      )}
    </>
  );
};

export default SignupTutorial;