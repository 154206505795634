import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Skeleton, Card, CardContent, CardActions } from '@mui/material';
import healthCard from './../components/client/health-card';
import api, { ClientHealth, WorkoutWeek } from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../components/PageTitle';
import ClientQuickView from '../containers/ClientQuickView';
import HealthCard from '../components/client/health-card';
import PlanificationCard from '../components/client/planification-card';
import { formatDate } from '../utils/dates';


interface ClientPlanificationsPageProps {
  clientId?: number;
}
const LIMIT = 5;
const ClientPlanificationsPage: React.FC<ClientPlanificationsPageProps> = () => {
  const { id = '' } = useParams();
  const clientId = parseInt(id);
  const [planifications, setPlanifications] = useState<WorkoutWeek[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [request, setRequest] = useState({ status: 'initial', error: '' });
  const navigate = useNavigate();
  const { t } = useTranslation();


  const fetchPlanifications = async (clientId: number) => {
    setRequest({ status: 'loading', error: '' });
    const response = await api.clients.getPlanifications(clientId, page, LIMIT);
    if (response.success && response.data) {
      setPlanifications([ ...planifications, ...response.data ]);

      setRequest({ status: 'success', error: '' });
      if (response.data.length < LIMIT) {
        setHasMore(false);
      }
      setPage(page + 1);

    }else {
      setRequest({ status: 'error', error: response.error || 'Error fetching client' });
    }
  };
  const handleNextPage = () => {
    if (!clientId)  return;
    fetchPlanifications(clientId);
  }

  const handleSeePlanification = (id: string) => (e: any) => {
    navigate(`/workoutweeks/${id}`);
  };

  useEffect(() => {
    if (clientId)
      fetchPlanifications(clientId);
  }, [clientId]);

  return (
    <Box>
      <PageTitle title={t('ClientPlanifications.title')} />
      <Box>
        <ClientQuickView clientId={clientId} />
      </Box>
      {(planifications.map(workout => (
          <Box key={workout.id} mb={2}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                {t('PlanificationCard.date', { date: workout.createdAt ? formatDate(workout.createdAt) : '-'})}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {workout.name} 
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {workout.description}
                </Typography>
                
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" onClick={handleSeePlanification(workout.id || '')}>
                  { t('PlanificationCard.seePlanification')}
                </Button>
              </CardActions>
            </Card>
          </Box>
        ))
      )}
      {request.status === 'loading' && (
        <Box  width={['100%', '400px']}>
          <Skeleton variant="rectangular" width="100%" height="100px"/>
        </Box>
      )}
      <Box mb={3} mt={2} display="flex" justifyContent="center">
        {hasMore && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextPage}
          >
            {t('common.loadMore')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ClientPlanificationsPage;