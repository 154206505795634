import React from 'react';
import { ReactElement, FC, useState, ChangeEvent, useEffect, useContext } from "react";
import { useTheme } from "@mui/material";
import api, { Program, ProgramStep, Room } from "../api";
import { UserContext } from "../contexts/userContext";
import { useNavigate } from "react-router-dom";
import ModalConversation from '../components/chat/ModalConversation';

type RoomsState = {
  data: Array<Room>,
  status: string,
  error?: string,
}

type SessionValues = {
  room: string,
  time: string,
  details: string,
}

type CreateProgramDraft = {
  status: string,
  data?: any,
  error?: string,
};


const INITIAL_SESSION = { room: '', time: '', details: '' };

const ProgramsAddAi: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const [rooms, setRooms] = useState<RoomsState>({ data: [], status: 'initial' });
  const [sessionValues, setSessionValues] = useState<SessionValues>(INITIAL_SESSION)
  const [createProgramDraft, setCreateProgramDraft] = useState<CreateProgramDraft>({ data: null, status: "initial" })
  const { user } = useContext(UserContext);


  const getRooms = async () => {
    setRooms({ status: "loading", data: [] })
    const res = await api.rooms.get();
    if (res.success) {
      setRooms({ data: res.data, status: "success" });
      if (res.data?.length) {
        setSessionValues((values) => ({ ...values, room: res.data[0].id }));
      }
    } else {
      setRooms({ data: [], status: "error", error: res.error });
    }
  }

  const handleCreateProgramDraft = async () => {
    setCreateProgramDraft({ status: "loading" })
    const res = await api.openai.programDraft(parseInt(sessionValues.room), parseInt(sessionValues.time), sessionValues.details);
    if (res.success) {
      setCreateProgramDraft({ status: "success", data: res.data });
    } else {
      setCreateProgramDraft({ status: "error", error: res.error });
    }
  }

  const handleClose = () => navigate('/training_classes');

  useEffect(() => {
    getRooms();
  }, [])


  return (
    <ModalConversation onClose={handleClose}/>
  );
};

export default ProgramsAddAi;
