import React, { ReactElement, FC, useEffect, useState, ChangeEventHandler, ChangeEvent, ReactEventHandler, useContext } from "react";
import { Alert, AlertTitle, Box, Button, CircularProgress, Container, Divider, FormControl, IconButton, InputAdornment, InputLabel, NativeSelect, Select, SelectChangeEvent, Slider, TextField, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import api, { Room } from "../api";
import { Program, ProgramStep } from "../api";
import { seconds, formattedTime } from "../utils/times";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";

type StatePrograms = {
  data: Array<Program>,
  status: string,
}
type StateRooms = {
  data: Array<Room>,
  status: string,
}
type CreateTrainingClass = {
  status: string,
  data?: any,
  error?: string,
}
const TrainingClassesAdd: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [program, setProgram] = useState<string>('');
  const [room, setRoom] = useState<string>('');
  const [startDate, setStartDate] = useState();
  const [programs, setPrograms] = useState<StatePrograms>({ data: [], status: "initial" });
  const [rooms, setRooms] = useState<StateRooms>({ data: [], status: "initial" });
  const [createTrainingClass, setCreateTrainingClass] = useState<CreateTrainingClass>({ status: 'initial' });
  const { user } = useContext(UserContext);

  const getPrograms = async () => {
    setPrograms({ data: [], status: "loading" })
    const programs = await api.programs.get();
    setPrograms({ data: programs.data, status: "success" });
  }
  const getRooms = async () => {
    setRooms({ data: [], status: "loading" })
    const rooms = await api.rooms.get();
    setRooms({ data: rooms.data, status: "success" });
  }

  useEffect(() => {
    getPrograms();
    getRooms();
  }, []);


  const handleSubmitTrainingClass = async () => {
    if (!program) return;
    setCreateTrainingClass({ status: 'loading' })
    
    const trainingClass = {
      programId: parseInt(program),
      name,
      duration: 60,
      professorId: user.professor?.id,
      roomId: parseInt(room),
      created: startDate,
    }

    const response = await api.trainingClasses.create(trainingClass);
    if (response.success) {
      setCreateTrainingClass({ status: 'success', data: response.data });
      setTimeout(() => {
        navigate('/training_classes', { replace: true })
      }, 700);
    }else {
      setCreateTrainingClass({ status: 'error', error: response.error });
    }
  }

  const handleAcceptStartDate = (date: any) => {
    setStartDate(date);
  }

  

  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
    >
      <PageTitle title="Crear clase" />
      <Box display="flex" flexDirection="column" p={2} maxWidth={400}>
        <Box  display="flex" flexDirection="column" gap={2}>
          <TextField
            style={{ flex: 1 }}
            variant="standard"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
            label={"Nombre clase"}
          />
          <FormControl style={{ flex: 3 }}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Programa de entrenamiento
            </InputLabel>
            <NativeSelect
              value={program}
              onChange={(evt) => setProgram(evt.target.value)} 
            >
              <option value=""></option>
              {programs.data.map((p: Program) => <option key={p.id} value={p.id}>{p.description}</option>)}
            </NativeSelect>
          </FormControl>
          <Box display="flex" gap={1}>
            <FormControl style={{ flex: 1 }}>
              <TextField
                variant="standard"
                value={60}
                disabled
                type="number"
                label={"Duración"}
              />
            </FormControl>
            <FormControl style={{ flex: 4 }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Sala
              </InputLabel>
              <NativeSelect
                value={room}
                onChange={(evt) => setRoom(evt.target.value)} 
              >
                <option value=""></option>
                {rooms.data.map((p: Room) => <option key={p.id} value={p.id}>{p.name}</option>)}
              </NativeSelect>
            </FormControl>
          </Box>
            <Box mt={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker label="Fecha de comienzo" onAccept={handleAcceptStartDate} />
              </LocalizationProvider>
            </FormControl>
          </Box>
        </Box>
        {createTrainingClass.status === "error" && (
          <Box mt={2}>
            <Alert severity="error">
              <AlertTitle>{createTrainingClass.error || 'No se ha podido crear la clase.'}</AlertTitle>
            </Alert>
          </Box>
        )}
        {createTrainingClass.status === "success" && (
          <Box mt={2}>
            <Alert severity="success">
              <AlertTitle>Clase creada con éxito</AlertTitle>
            </Alert>
          </Box>
        )}
        <Box mt={2}>
          <Button
            variant="contained"
            disabled={createTrainingClass.status === "loading"}
            onClick={handleSubmitTrainingClass}
            fullWidth
          >
            {createTrainingClass.status === "loading" && <CircularProgress size="small" />}
            Crear clase de entrenamiento
          </Button>
        </Box>
        
      </Box>
    </Box>
  );
};

export default TrainingClassesAdd;