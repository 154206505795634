import React, { ReactElement, FC, useEffect, useContext, useState } from "react";
import { Box } from "@mui/material";
import api, { TrainingSession } from "../api";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import TrainingsList from "../components/TrainingsList";
import TrainingsListContainer from "../containers/TrainingsListContainer";
import ConversationThreadContainer from "../containers/ConversationThreadContainer";
import { useParams } from "react-router-dom";

const ConversationPage: FC<any> = (): ReactElement => {
  const { user } = useContext(UserContext);
  const { threadId = '' } = useParams();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
    >
      <ConversationThreadContainer threadId={threadId} />
    </Box>
  );
};

export default ConversationPage;