import React, { ReactElement, FC, useEffect, useState, useContext } from "react";
import { Box, Button,  List, ListItemButton, ListItemAvatar, ListItemText, Typography, SpeedDial, SpeedDialIcon, Divider, ListItemSecondaryAction } from "@mui/material";
import api, { TrainingClass } from "../api";
import { Link, useNavigate } from "react-router-dom";
import { ClassOutlined, Psychology, Visibility, VisibilityOff } from "@mui/icons-material";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";

type TrainingClassesState = {
  data: TrainingClass[]
  error?: string
  status: string
}

const TrainingClasses: FC<any> = (): ReactElement => {
  const [trainingClasses, setTrainingClasses] = useState<TrainingClassesState>({ data: [], status: "initial" });
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const isProfessor = user.role === 'PROFESSOR';
  const isClient = user.role === 'CLIENT';


  const getTrainingClasses = async () => {
    setTrainingClasses({ data: [], status: "loading" })
    const trainingClass = await api.trainingClasses.get();
    setTrainingClasses({ data: trainingClass.data, status: "success" });
  }

  const handleToggleHidden = (trainingClassId: number) => async (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const tclass = await api.trainingClasses.toggleHidden(trainingClassId);

    if (tclass.success) {
      setTrainingClasses((values) => ({
        ...values,
        data: values.data.map(tclass => tclass.id === trainingClassId ? ({ ...tclass, hidden: !tclass.hidden }) : tclass),
      }))
    } else {
      console.error(tclass.error);
    }
  }

  const handleClick = (p: TrainingClass) => () => {
    navigate(`/training_classes/${p.id}`);
  }

  useEffect(() => {
    getTrainingClasses();
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
    >
      {isProfessor && (
        <SpeedDial
          ariaLabel="Add training class"
          sx={{ position: 'fixed', bottom: 75, right: 16 }}
          icon={<SpeedDialIcon onClick={() => navigate("/training_classes/add")}/>}
        />
      )}
      {isClient && (
        <SpeedDial
          ariaLabel="Try AI"
          sx={{ position: 'fixed', bottom: 75, right: 16 }}
          icon={<Psychology onClick={() => navigate("/programs/openai")}/>}
        />
      )}
      <List
        sx={{ bgcolor: 'background.paper', paddingBottom: '70px' }}
        component="nav"
      >
        {trainingClasses.data.map((p: TrainingClass) => (
          <ListItemButton key={p.id} onClick={handleClick(p)}>
            <ListItemText
              primary={p.name}
              secondary={`${p.room?.name}. Programa ${p.program?.name}`}
            />
            {isProfessor && (
              <ListItemSecondaryAction onClick={handleToggleHidden(p.id)}>
                {p.hidden ? <VisibilityOff /> : <Visibility />}
              </ListItemSecondaryAction>
            )}
            <Divider />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

export default TrainingClasses;