import { GraphicEq, KeyboardVoice } from '@mui/icons-material';
import { Button, IconButton, Theme, useTheme } from '@mui/material';
import React, { useState } from 'react';

type AudioRecorderProps = {
  onRecordedAudio: Function,
  onStartAudio: Function,
};

const styles = (theme: Theme) => ({
  button: {
    borderRadius: theme.spacing(6),
    flex: 1,
    width: theme.spacing(6),
    maxWidth: theme.spacing(6),
    height: theme.spacing(6),
    minWidth: theme.spacing(6),
    marginRight: '2px'
  },
});


const AudioRecorder = ({ onRecordedAudio, onStartAudio }: AudioRecorderProps) => {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder|null>(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [recordingStatus, setRecordingStatus] = useState<string>('initial');
  const [audioURL, setAudioURL] = useState<string>('');
  const [enableDragMouse, setEnableDragMouse] = useState(false);
  const theme = useTheme();
  const classes = styles(theme);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function(stream) {
        setRecordingStatus('recording');
        onStartAudio();
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        const chunks: BlobPart[] = [];

        recorder.ondataavailable = function(e) {
          console.log(e.data);
          chunks.push(e.data);
        };

        recorder.onstop = function(e) {
          console.log('chucnks', chunks);
          const audioBlob = new Blob(chunks, { 'type' : 'audio/ogg; codecs=opus' });
          const url = URL.createObjectURL(audioBlob);
          setRecordingStatus('initial');
          setAudioURL(url);
          onRecordedAudio(audioBlob);
        };

        recorder.start();
      })
      .catch(function(err) {
        console.error('getUserMedia error: ', err);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };
  const handleMove = (evt: any) => {
    if (enableDragMouse) {
      console.log('mouse', evt.clientX, evt.clientY);

    } 
  }
  const handleTouchMove = (evt: any) => {
    console.log('touch: ', evt);
  }
  
  const handleClickButton = recordingStatus === 'initial' ? startRecording : stopRecording;
  //{audioURL && <audio controls src={audioURL} />}
  return (
    <Button variant="contained" color="primary" sx={classes.button} onClick={handleClickButton} onTouchMove={handleTouchMove}>
      {recordingStatus === 'initial' && (<KeyboardVoice />)}
      {recordingStatus === 'recording' && (<GraphicEq />)}
      
    </Button>
  );
};

export default AudioRecorder;
