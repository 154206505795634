import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface CountUpDownProps {
  start: number;
  end: number;
  duration: number; // duration in milliseconds
  label?: string
}

const CountUpDown: React.FC<CountUpDownProps> = ({ start, end, duration, label }) => {
  const [count, setCount] = useState(start);
  const stepTime = duration / Math.abs(end - start);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(prevCount => {
        const newCount = prevCount + (start < end ? 1 : -1);
        if ((start < end && newCount >= end) || (start > end && newCount <= end)) {
          clearInterval(interval);
          return end;
        }
        return newCount;
      });
    }, stepTime);

    return () => clearInterval(interval);
  }, [start, end, duration, stepTime]);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h1" component={"span"}>
       {count}
       </Typography>
       <Typography variant="h4" sx={{ ml: 2 }} component="span">{label}</Typography>
    </Box>
  );
};

export default CountUpDown;