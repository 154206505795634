import React, { ReactElement, FC, useEffect, useContext, useState } from "react";
import { Box } from "@mui/material";
import api, { Program, Room, TrainingSession } from "../api";
import RoomsList from "../components/RoomsList";
import ConverstationComponent from "../components/chat/Conversation";
import { UserContext } from "../contexts/userContext";
import ConversationProgram from "../components/chat/ConversationProgram";
import { extractDomaProgramFromText } from "../utils/json.extract";

type RoomsState = {
  data?: Room[],
  status: string,
  error?: string,
}
type ContainerProps = {
  onProgramSelected?: Function,
};

type RequestState = {
  status: string,
  program?: Program,
}


const ConversationContainer: FC<ContainerProps> = ({ onProgramSelected = () => {} }): ReactElement => {
  const [rooms, setRooms] = useState<RoomsState>({ data: [], status: 'initial' });
  const [messages, setMessages] = useState<any>([]);
  const [threadId, setThreadId] = useState<string>('');
  const [request, setRequest] = useState<RequestState>({ status: 'initial' })
  const { user } = useContext(UserContext);
  const isProfessor = user.role === 'PROFESSOR';

  const getRooms = async () => {
    setRooms({ data: [], status: 'loading' });
    const res = await api.rooms.getWithTrainings()

    if (res.success) {
      setRooms({ data: res.data, status: 'success' });
    }else {
      setRooms({ data: [], status: 'error', error: res.error })
    }
  }

  const sendFirstMessage = async () => {
    setRequest({ status: 'loading' });
    const response = await api.openai.chat('', `Hola`);
    if (response.success) {
      const threadId = response.data.thread_id;
      const msg = response.data.content[0].text.value;
      setMessages([ ...messages, { text: msg, role: 'assistant' }])
      setThreadId(threadId);
    }
    setRequest({ status: 'success' });
  }

  const uploadFileToServer = async (blob: Blob) => {
    try {
      const res = await api.openai.transcript(blob);
      if (res.success) {
        handleSendMessage(res.data?.text);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSendMessage = async (message: string | Blob) => {
    if (typeof message === 'object') {
      uploadFileToServer(message);
      return;
    }
    const msgs = [ ...messages, { text: message, role: 'user' }];
    setRequest({ status: 'loading' })
    setMessages(msgs)
    const response = await api.openai.chat(threadId, message, 'doma');
    if (response.success) {
      const { msg, program } = extractDomaProgramFromText(response.data.content[0].text.value);
      
      setMessages([ ...msgs, { text: msg, role: 'assistant' }])

      if (program) {
        // TODO: fixing the AI output
        const totalTime = program.steps.reduce((acc, curr) => acc + curr.duration, 0)
        program.steps = program?.steps.map(s => ({ ...s, efforts: s.efforts*100, duration: totalTime < 70 ? s.duration * 60 : s.duration })) || [];
        onProgramSelected(program);
      }
      setRequest({ status: 'success', program })
    }else {
      setRequest({ status: 'error' })
    }

  }

  useEffect(() => {
    getRooms();
    if (user?.name && !threadId) {
      sendFirstMessage();
    }
  }, [user]);

  return isProfessor ? (
    <ConversationProgram messages={messages} onSendMessage={handleSendMessage} request={request} />
  ) :(
    <ConverstationComponent messages={messages} onSendMessage={handleSendMessage} request={request} />
  );
};

export default ConversationContainer;