import React, { ReactElement, FC, useEffect, useState, useContext } from "react";
import { Box,  List, ListItemButton, ListItemAvatar, ListItemText, Typography, Divider, Collapse, Button, ListItem, ListItemSecondaryAction } from "@mui/material";
import api, { RankingElement, Room } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import RoomIcon from '@mui/icons-material/Room';
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import { PersonRemove } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type EndTrainingState = {
  trainingId: number | null
  status: string
  error?: string
};

const RankingPage: FC<any> = (): ReactElement => {
  const [ranking, setRankingPage] = useState({ data: [], status: "initial" });
  const [endTraining, setEndTraining] = useState<EndTrainingState>({ trainingId: null, status: "initial" });
  const [expendedId, setExpandedId] = useState<number|null>(null);
  const [room, setRoom] = useState<Room|null>();
  const { roomId = '' } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation(); 

  const getRankingCurrent = async (roomId: number) => {
    setRankingPage({ data: [], status: "loading" })
    const trainingClass = await api.rankings.getByRoom(roomId);
    setRankingPage({ data: trainingClass.data, status: "success" });
  }
  const getRoomData = async (roomId: number) => {
    const rooms = await api.rooms.get();
    const room = rooms.data.find((r: Room) => r.id === roomId);
    setRoom(room);
  }

  const handleEndTraining = (trainingId: number) => async () => {
    setEndTraining({ trainingId, status: "loading" });
    const res = await api.trainingSessions.end(trainingId);
    if (res.success) {
      getRankingCurrent(parseInt(roomId));
      setEndTraining({ trainingId: null, status: "success" });
      setExpandedId(null);
    }else {
      setEndTraining({ trainingId: null, status: "success", error: res.error });
    }
  }

  useEffect(() => {
    if (roomId) {
      getRankingCurrent(parseInt(roomId));
      getRoomData(parseInt(roomId));
    }
  }, [roomId]);

  return (
    <Box>
      <PageTitle  title={t('Ranking.title')} />
      <Box mb={2} display="flex" gap={1}>
        <RoomIcon /><Typography variant="body1">{room?.name}</Typography>
      </Box>
      <List
        sx={{ bgcolor: 'background.paper', paddingBottom: '70px' }}
        component="nav"
      >
        {ranking.data.map((training: RankingElement) => (
          <React.Fragment key={training.trainingId}>
            <ListItemButton>
              <ListItemText
                primary={training.nick}
                secondary={t('Ranking.data', { points: training.points, lastBPM: training.lastBPM, currentEffort: training.currentEffort })}
              />
              <Box>
                
                <Collapse in={expendedId !== training.trainingId}>
                  <ListItemSecondaryAction onClick={() => setExpandedId(training.trainingId)}>
                    <PersonRemove />
                  </ListItemSecondaryAction>
                </Collapse>
                <Collapse in={expendedId === training.trainingId}>
                  <ListItem sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Button color="secondary" onClick={() => setExpandedId(null)}>{t('Ranking.cancel')}</Button>
                    <Button color="primary" onClick={handleEndTraining(training.trainingId)} disabled={expendedId === endTraining.trainingId}>{t('Ranking.confirm')}</Button>
                  </ListItem>
                </Collapse>
              </Box>
              
              <Divider />
            </ListItemButton>

            
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default RankingPage;