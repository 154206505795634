import React, { ReactElement, FC, useState, useContext } from "react";
import { Box, Typography,Container, Tabs, Tab, AppBar } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import TabPanel from "../components/common/TabPanel";
import QuickSessionPage from "./QuickSessionPage";
import Clients from "./Clients";
import { useTranslation } from "react-i18next";
import AddClientTutorial from "../components/tutorial/AddClientTutorial";
import { UserContext } from "../contexts/userContext";

const TAB_CLIENTS = 0;
const TAB_QUICK = 1;

const ClientsPage: FC<any> = (): ReactElement => {
  const [tab, setTab] = useState(TAB_CLIENTS);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  
  return (
    <Box
    >
      <PageTitle title={t('Clients.title')} />    
      <AddClientTutorial gym={user.gym} />
      <Clients />
      
    </Box>
  );
};

export default ClientsPage;