import React, { useEffect, useState } from 'react';
import { Backdrop, Card, CardContent, Typography, CardActions, Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Box, Slide, List, Alert, SpeedDial, SpeedDialIcon } from '@mui/material';
import { Gym, User, WorkoutWeek } from '../../api';
import { useTranslation } from 'react-i18next';
import Transition from '../common/Transition';
import { GymCard } from '../profile/GymCard';
import { AssignmentOutlined, InfoOutlined, Widgets } from '@mui/icons-material';
import ReactDOM from 'react-dom';
import { ListItemAction } from '../Navbar';
import './effects.css';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CountUpDown from '../common/CountUpDown';

interface AddClientTutorialProps {
  gym?: Gym;
  first?: boolean | null;
}

const AddClientTutorial: React.FC<AddClientTutorialProps> = ({ gym, first }) => {
  const [step, setStep] = React.useState('initial');
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!gym?.tutorialSteps?.firstPlanification && !first) {
      setStep('start');
    }
    if (first) setStep('initial')

  }, [gym, first]);

  return (
    <>
      {step !== 'initial' && (
        <Backdrop open sx={{ zIndex: 10000 }}>
          {step === 'start' && (
            <>
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.addClientTutorialTitle')}</DialogTitle>
                <DialogContent>
                  {t('Tutorial.addClientTutorialDescription')}
                  <Typography variant="body1">{t('Tutorial.addClientTutorialCreate')}</Typography>
                  <SpeedDial
                    ariaLabel="create client"
                    icon={<SpeedDialIcon />}
                    sx={{ mt: 2}}
                  />
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={() => setStep('initial')}>
                    {t('Tutorial.understood')}
                  </Button>
                </DialogActions>
                  <SpeedDial
                    onClick={() => navigate("/clients/new")}
                    ariaLabel="create client"
                    icon={<SpeedDialIcon />}
                    className="glow-effect-circle"
                    sx={{ bottom: 75, right: 16, position: 'fixed', zIndex: 10002, '> button': { marginTop: '-15px' }} }
                  />
              </Dialog>
          </>
          )}
         </Backdrop>
      )}
    </>
  );
};

export default AddClientTutorial;