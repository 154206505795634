import React, { ReactElement, FC, useContext, useState, useEffect } from "react";
import { Box, Typography,Container, Button, Alert, AlertTitle, CircularProgress } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { Link, Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import api, { User } from "../api";
import { useTranslation } from "react-i18next";

const VerifyPage: FC<any> = (): ReactElement => {

  const { id = '' } = useParams();
  const [params] = useSearchParams()
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>();
  const [request, setRequest] = useState({ status: 'initial', error: '' })
  const { t } = useTranslation(); 

  const handleVerify = async (userId: number, code: string) => {
    setRequest({ status: 'loading', error: '' });
    const res = await api.auth.verify(userId, code);

    if (res.success) {
      setRequest({ status: 'success', error: '' });
      setUser(res.data);
      setTimeout(() => {
        navigate('/sign-in');
      }, 3000);
    }else {
      setRequest({ status: 'error', error: res.error });
    }
  }
  
  useEffect(() => {
    localStorage.removeItem('token');
    handleVerify(parseInt(id), params.get('code') || '')
  }, []);

  return (
    <Box
    >
      <Typography variant="h3">{t('Verify.verifyingAccount')}</Typography>
      {request.status === 'success' && (
        <Alert severity="success">
          <AlertTitle>{t('Verify.validationSuccess', { name: user?.name })}</AlertTitle>
        </Alert>
      )}
      {request.status === 'loading' && (
        <CircularProgress />
      )}
      {request.status === 'error' && (
        <Alert severity="error">
          <AlertTitle>{t('Verify.validationError')}</AlertTitle>
        </Alert>
      )}
    </Box>
  );
};

export default VerifyPage;