import React from 'react';
import './logoLoading.css'; // Import the CSS file for animation

const LogoLoading: React.FC = () => {
  return (
    <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M88.0757 83.5252C84.8267 80.6626 79.6272 76.8057 76.1312 74.9627C61.6337 78.6552 46.4419 78.6552 31.9444 74.9627C28.4485 76.793 22.8499 80.6626 20 83.5315C41.2226 90.8084 67.3281 90.5424 88.0757 83.5315" fill="url(#paint0_linear_42_2406)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M36.5569 72.5941L27.456 25.3674L39.869 25.3674L42.5227 45.6336C44.885 40.0097 48.5393 31.7006 54.0619 25C57.4565 29.655 63.625 38.6228 65.5756 45.5766L68.2229 25.38L80.6107 25.38L71.5542 72.4991C67.7602 71.8886 63.9355 71.4869 60.0974 71.2958C58.7928 61.1627 56.5381 54.6965 54.1125 49.3576C51.6362 54.7725 49.4133 61.3907 48.115 71.3148C44.2391 71.4888 40.3784 71.9119 36.5569 72.5814" fill="url(#paint1_linear_42_2406)"/>
      <circle cx="55" cy="55" r="50" stroke="url(#paint0_linear_42_2406)" strokeWidth="4" strokeDasharray="150" className="rotating-circle" />
      <defs>
        <linearGradient id="paint0_linear_42_2406" x1="-3.04388e-07" y1="56.9263" x2="88.0757" y2="56.9263" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9B72FE"/>
          <stop offset="1" stopColor="#5D4498"/>
        </linearGradient>
        <linearGradient id="paint1_linear_42_2406" x1="27.45605" y1="23.7971" x2="80.6107" y2="23.7971" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9B72FE"/>
          <stop offset="1" stopColor="#5D4498"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoLoading;