import React, { FC, useState } from 'react'
import { Theme, useTheme } from "@mui/material";

import { TextField, Button, Box, Paper, Typography, Container, Alert, AlertTitle } from '@mui/material';
import SendIcon from '@mui/icons-material/SendOutlined';

import AudioButton from './AudioButton';

const styles = (theme: Theme) => ({
  wrapForm : {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      margin: `3px`,
      padding: 0,
  },
  wrapText  : {
      flex: 9,
      marginRight: "4px",
      "> div": {
        borderRadius: theme.spacing(4),
      }
  },
  button: {
    borderRadius: theme.spacing(6),
    flex: 1,
    width: theme.spacing(6),
    maxWidth: theme.spacing(6),
    height: theme.spacing(6),
    minWidth: theme.spacing(6),
  },
});


export const TextInput = ({ onSend, disabled }: { onSend: Function, disabled: boolean }) => {
  const theme = useTheme();
  const [text, setText] = useState('');
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const classes = styles(theme);

  const handleSend = () => {
    if (text.length) {
      onSend(text);
      setText('');
    }
  }
  const handleRecordedAudio = (audioUrl: Blob) => {
    console.log('audio url', audioUrl);
    //setAudioUrl(audioUrl);
    onSend(audioUrl);
    setIsRecording(false);
  }
  const handleStartAudio = () => {
    setAudioUrl('');
    setIsRecording(true);
  }
  return (
    <Container sx={classes.wrapForm}>
      {/* @ts-ignore */}
      {audioUrl && (<audio controls >
        <source src={audioUrl} type="audio/ogg"/>
        </audio>)}
      <TextField
          multiline={true}
          maxRows={3}
          sx={classes.wrapText}
          value={text}
          disabled={disabled || isRecording}
          onChange={(evt) => setText(evt.target.value)}
      />
      <Box flex={1} display="flex" alignItems="flex-end">
        <AudioButton
          onRecordedAudio={handleRecordedAudio}
          onStartAudio={handleStartAudio}
        />
        <Button variant="contained" disabled={disabled || isRecording} color="primary" sx={classes.button} onClick={handleSend}>
            <SendIcon />
        </Button>
      </Box>
    </Container>
  )
}



