import { ReactElement, FC, useState, ChangeEvent, useContext, useEffect } from "react";
import { Alert, AlertTitle, Box, Button, Divider, FormControl, Hidden, InputAdornment, InputLabel, NativeSelect, SpeedDial, TextField, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import api, { ProgramStep } from '../api';
import { seconds, formattedTime } from "../utils/times";
import { Program } from "../api";
import { UserContext } from "../contexts/userContext";
import { Link } from "react-router-dom";
import { PageTitle } from "../components/PageTitle";
import { Psychology } from "@mui/icons-material";
import ModalConversation from "../components/chat/ModalConversation";
import ConversationContainer from "../containers/ConversationContainer";
import { useTranslation } from "react-i18next";

type ResultState = {
  status: string,
  data?: string,
  error?: string,
};

const BASE_STEP = { duration: 0, efforts: 0, name: '' };

const ProgramsAdd: FC<any> = (): ReactElement => {
  const [steps, setSteps] = useState<Array<ProgramStep>>([ { ...BASE_STEP }]);
  const [name, setName] = useState<string>('');
  const [result, setResult] = useState<ResultState>({ status: 'initial' });
  const [showChat, setShowChat] = useState(false);
  const { t } = useTranslation(); 
  const { user } = useContext(UserContext);

  const handleSubmitProgram = async () => {
    setResult({ status: 'loading', error: '' })
    const program: Program = {
      authorId: user.professor?.id || 0,
      name,
      description: name,
      steps,
    }
    const response = await api.programs.create(program);
    
    if (response.success) {
      setResult({ status: 'success', data: response.data });

    }else {
      setResult({ status: 'error', error: response.error })
    }
  }

  const handleReuseProgram = () => {
    setResult({ status: 'initial' });
    setName('');
  }

  const handleStepChangeString = (field: string, index: number) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSteps((values) => {
    values[index] = { ...values[index], [field]: field === 'efforts' ? parseInt(event.target.value) : event.target.value }
    return [ ...values ];
  });

  const handleStepChangeSelectTime = (field: string, index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => setSteps((values) => {
    values[index] = { ...values[index], [field]: parseInt(event.target.value) }
    return [ ...values ];
  });

  const handleAddStep = () => setSteps(steps.concat({ ...BASE_STEP }));
  const handleRemoveStep = () => {
    steps.pop(); setSteps([ ...steps ]);
  };

  const handleProgramSelected = (program: Program) => {
    setName(program.name);
    setSteps(program.steps);
    // TODO: set reasoning?
  }

  // TODO: make all body not scroll
  useEffect(() => {
    document.getElementsByTagName('body')[0].setAttribute('scroll', 'hidden');
    return () => {

      document.getElementsByTagName('body')[0].removeAttribute('scroll');
    }
  }, []);


  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ height: '100%' }}      
      pb={3}
    >
      <PageTitle title={t('ProgramsAdd.title')} />
      <Box display="flex" sx={{ height: '100%' }}>
        <Box flex="1" display="flex" flexDirection="column" p={1} pb="100px" sx={{  }}>
          {steps.map((step, index) => (
            <Box key={`${step}-${index}`} display="flex" flexDirection="column" mb={2}>
              <Box display="flex" flexWrap="wrap" justifyContent="space-around" p={2}>
                <Box flex={[null, 8]} flexGrow="10" pb={[2, 0]}>
                  <FormControl fullWidth>
                    <TextField
                      variant="standard"
                      value={step.name}
                      onChange={handleStepChangeString('name', index)}
                      label={t('ProgramsAdd.exerciseName')}  
                    />
                  </FormControl>
                </Box>
                <Hidden mdUp>
                  <Box style={{ flexBasis: "100%", height: 0 }} />
                </Hidden>
                <Box flex={[2]}>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      {t('ProgramsAdd.time')}
                    </InputLabel>
                    <NativeSelect
                      value={step.duration}
                      onChange={handleStepChangeSelectTime('duration', index)} 
                    >
                      {seconds.map(s => <option key={s} value={s}>{formattedTime(s)}</option>)}
                    </NativeSelect>
                  </FormControl>
                </Box>
                <Box flex={[2]}>
                  <FormControl fullWidth>
                    <TextField
                      label={t('ProgramsAdd.effort')}   
                      variant="standard"
                      value={step.efforts}
                      type="number"
                      inputProps={{ max: 100, min: 0 }}
                      onChange={handleStepChangeString('efforts', index)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box p={2}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    variant="standard"
                    value={step.description}
                    onChange={handleStepChangeString('description', index)}
                    label={t('ProgramsAdd.exerciseExtraInfo')}  
                  />
                </FormControl>
              </Box>
              
              <Divider style={{ margin: '20px 0' }}/>
            </Box>
          ))}
          <Box>
            <Box display="flex">
              <TimerOutlinedIcon color="primary"/>
              <Typography variant="body1">
                {formattedTime(steps.reduce((acc, curr) => acc + curr.duration, 0))}
              </Typography>
            </Box>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-around">
            <Button variant="contained" startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddStep}>
              {t('ProgramsAdd.addStep')}
            </Button>
            <Button variant="outlined" color="error" startIcon={<DeleteOutlineOutlinedIcon />} onClick={handleRemoveStep}>
              {t('ProgramsAdd.removeLast')}
            </Button>
          </Box>
          {result.status === "error" && (
            <Box mt={2}>
              <Alert severity="error">
                <AlertTitle>{result.error || t('ProgramsAdd.couldntCreateProgram')}</AlertTitle>
              </Alert>
            </Box>
          )}
          {result.status === "success" && (
            <Box mt={2}>
              <Alert severity="success">
                <AlertTitle>{t('ProgramsAdd.programCreated')} <Link to="/programs">{t('ProgramsAdd.seeList')}</Link></AlertTitle>
                <Box mt={1} justifyContent="center">
                  <Button color="primary" onClick={handleReuseProgram}>{t('ProgramsAdd.reutilizeAndCreate')}</Button>
                </Box>
              </Alert>
            </Box>
          )}
          <Box mt={2}>
            <TextField
              fullWidth
              variant="standard"
              style={{ marginBottom: 10 }}
              value={name}
              onChange={({ target }) => setName(target.value)}
              label={"Nombre del programa"}  
            />
            <Button variant="contained" onClick={handleSubmitProgram} fullWidth>
              {t('ProgramsAdd.createProgram')}
            </Button>
          </Box>
        </Box>
        <Hidden smDown>
          <Divider orientation="vertical"  />
          <Box flex="1" display="flex" flexDirection="column" p={0}  sx={{ background: 'white', height: '100%' }}>
            <ConversationContainer onProgramSelected={handleProgramSelected} />
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};

export default ProgramsAdd;