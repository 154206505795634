import React, { ReactElement, FC, useEffect, useState, useRef, useContext } from "react";
import { Box, Button, Typography, Alert } from "@mui/material";
import RoomIcon from '@mui/icons-material/Room';
import api, { TrainingClass, TrainingSession } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import TrainingProgramDetailsComponent from "../components/TrainingDetails";
import { PageTitle } from "../components/PageTitle";
import { UserContext } from "../contexts/userContext";


type TrainingClassState = {
  data?: TrainingClass,
  status: string,
  error?: string | boolean,
}

const TrainingClassPage: FC<any> = (): ReactElement => {
  const containerRef = useRef<any>();
  const { id = '' } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [trainingClass, setTrainingClass] = useState<TrainingClassState>({ status: "initial" });

  const getTrainingClass = async (id: number) => {
    setTrainingClass({ status: "loading" })
    const trainingClass = await api.trainingClasses.getById(id);
    if (trainingClass.success) {
      const data: TrainingClass | undefined = trainingClass.data;
      
      setTrainingClass({ data, status: "success" });
    } else {
      setTrainingClass({ status: "error", error: trainingClass.error })
    }
  }

  const handleCreateTrainingSession = async () => {
    if (!trainingClass.data || !user.id) return;
    const currentBand = user.client?.bands?.length ? user.client?.bands[user.client.bands.length - 1].band : null;
    console.log(user)
    if (!currentBand) {
      setTrainingClass((values) => ({ ...values, status: 'error', error: 'No tienes una banda asociada' }))
      return;
    }
    setTrainingClass((values) => ({
      ...values,
      status: 'loading',
    }));
    const createResponse: any = await api.trainingSessions.create(user.clientId, currentBand.code, trainingClass.data);
    if (createResponse.success) {
      const sessionId: string = createResponse.data?.id;

      setTrainingClass((values) => ({
        ...values,
        status: 'success',
      }));
      navigate(`/training_sessions/${sessionId}`);
    } else {
      setTrainingClass((values) => ({
        ...values,
        status: "error",
        error: createResponse.error,
      }));
    }
  }

  useEffect(() => {
    getTrainingClass(parseInt(id));
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
      sx={{ paddingBottom: 8 }}
    >
      <PageTitle title={trainingClass.data?.name} />
      <Box mb={2} display="flex" gap={1}>
        <RoomIcon /><Typography variant="body1">{trainingClass.data?.room.name}</Typography>
      </Box>
      {trainingClass.data?.program && (<TrainingProgramDetailsComponent program={trainingClass.data?.program} />)}
      {user.professorId ? (
        <></>
      ) : (
        <Box display="flex" flexDirection="column">
          <Button disabled={trainingClass.status === 'loading'} variant="contained" color="primary" onClick={handleCreateTrainingSession}>
            Ingresar!
          </Button>
          {trainingClass.status === 'error' && (
            <Alert severity="error">{trainingClass.error}</Alert>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TrainingClassPage;