import React, { useState } from 'react';
import ClientGoalAndPreferences from '../common/ClientGoalAndPreference';
import { Alert, Box, Button, DialogActions, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { HeightOutlined, MonitorWeightOutlined } from '@mui/icons-material';
import { ClientHealth } from '../../api';
import { useTranslation } from 'react-i18next';

type Props = {
  onSave: ({ weight, height, previousInjuries, previousExperience }: { weight: number, height: number, previousInjuries: string, previousExperience: string }) => void;
  status: string;
  onCancel: () => void;
  health?: ClientHealth; 
};

const HealthForm: React.FC<Props> = ({ onSave, status, health, onCancel }: Props) => {
  const [weight, setWeight] = useState<string>(health?.weight.toString() || '');
  const [height, setHeight] = useState<string>(health?.height.toString() || '');
  const [previousExperience, setPreviousExperience] = React.useState<string>(health?.previousExperience || '');
  const [previousInjuries, setPreviousInjuries] = React.useState<string>(health?.injuries || '');
  const { t } = useTranslation();
  const [previousInjuriesBool, setPreviousInjuriesBool] = React.useState<string>(health?.injuries ? t('HealthForm.yes') : t('HealthForm.no'));

  const handleExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousExperience((event.target as HTMLInputElement).value);
  };

  const handlepreviousInjuriesBoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousInjuriesBool((event.target as HTMLInputElement).value);
  };


  const handlePreviousInjuriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousInjuries((event.target as HTMLInputElement).value);
  };

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(event.target.value);
  };

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(event.target.value);
  };

  const handleSave = () => {
    onSave({ weight: parseInt(weight), height: parseInt(height), previousInjuries, previousExperience });
  };

  return (
    <Box width="100%" px={[2, 4]} py={[1,2]}>
      <Box display="flex" gap={2}>
        <TextField
          label={t('HealthForm.weightInKg')}
          type="number"
          value={weight !== null ? weight : ''}
          onChange={handleWeightChange}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MonitorWeightOutlined /> 
              </InputAdornment>
            ),
          }}
          inputProps={{ min: 0, max: 150 }}
        />
        <TextField
          label={t('HealthForm.heightInCm')}
          type="number"
          value={height !== null ? height : ''}
          onChange={handleHeightChange}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
            <InputAdornment position="start">
              <HeightOutlined />
            </InputAdornment>
            ),
          }}
          inputProps={{ min: 0, max: 220 }}
        />
      </Box>
      <Box width="100%" mb={2}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t('HealthForm.previousExperienceDescription')}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="previousExperience"
            name="previousExperience"
            value={previousExperience}
            onChange={handleExperienceChange}
            sx={{ flexDirection: 'column' }}
          >
            <FormControlLabel value={t('HealthForm.sedentaryValue')} control={<Radio />} label={t('HealthForm.sedentaryLabel')} />
            <FormControlLabel value={t('HealthForm.begginerValue')} control={<Radio />} label={t('HealthForm.begginerLabel')} />
            <FormControlLabel value={t('HealthForm.mediumValue')} control={<Radio />} label={t('HealthForm.mediumLabel')} />
            <FormControlLabel value={t('HealthForm.advancedValue')} control={<Radio />} label={t('HealthForm.advancedLabel')} />
          </RadioGroup>
        </FormControl>
        <Typography variant="subtitle1" color="grey" sx={{ marginTop: 2 }}>
          {t('HealthForm.selectAdditionalOption')}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="previousInjuries"
            name="previousInjuries"
            value={previousInjuriesBool}
            onChange={handlepreviousInjuriesBoolChange}
            sx={{ flexDirection: 'column' }}
          >
            <FormControlLabel value={t('HealthForm.noInjuriesValue')} control={<Radio />} label={t('HealthForm.noInjuriesLabel')} />
            <FormControlLabel value={t('HealthForm.yesInjuriesValue')} control={<Radio />} label={t('HealthForm.yesInjuriesLabel')} />
          </RadioGroup>
        </FormControl>
        {previousInjuriesBool === t('HealthForm.yesInjuriesValue') && (
          <TextField
            label={t('HealthForm.pleaseMoreDetails')}
            variant="outlined"
            value={previousInjuries}
            onChange={handlePreviousInjuriesChange}
            fullWidth
            sx={{ marginTop: 2 }}
          />
        )}
      </Box>
      {status === 'error' && (
        <Alert sx={{ my: 2}} severity="error">{t('HealthForm.errorWhenSaving')}</Alert>
      )}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={status === 'loading'}>
        {t('HealthForm.update')}
        </Button>
        <Button variant="text" color="primary" onClick={onCancel} disabled={status === 'loading'}>
          {t('HealthForm.cancel')}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default HealthForm;