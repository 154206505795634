import React, { FC, ReactNode } from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { UserContextProvider } from "../contexts/userContext";

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <UserContextProvider>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          maxWidth: "100vw",
          //height: "100vh",
          flexGrow: 1,
        }}
      >
        <Navbar />
        <Container sx={{ overflow: 'auto', marginTop: 10 }}>
          {children}
        </Container>
      </Box>
    </UserContextProvider>

  );
};

export default Layout;