import React, { ReactElement, FC, useEffect, useContext, useState } from "react";
import { Box } from "@mui/material";
import api, { TrainingSession } from "../api";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import TrainingsList from "../components/TrainingsList";
import TrainingsListContainer from "../containers/TrainingsListContainer";
import { useTranslation } from "react-i18next";

const HistoryPage: FC<any> = (): ReactElement => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
    >
      <PageTitle title={t('HistoryTrainings.title')} />
      <TrainingsListContainer clientId={user.clientId} />
    </Box>
  );
};

export default HistoryPage;