import React, { useState, useEffect } from 'react';
import { Avatar, CircularProgress, Typography, Box, Skeleton } from '@mui/material';
import api from './../api';
import { Link } from 'react-router-dom';

const getClient = async (clientId: number, setClient: React.Dispatch<React.SetStateAction<any>>) => {
  setClient({ status: 'loading' });
  const res = await api.clients.getById(clientId);

  if (res.success) {
    setClient({ status: 'success', data: res.data });
  } else {
    setClient({ error: res.error, status: 'error' });
  }
};

const ClientQuickView: React.FC<{ clientId: number }> = ({ clientId }) => {
  const [client, setClient] = useState<{ status: string; data?: any; error?: string }>({ status: 'idle' });

  useEffect(() => {
    getClient(clientId, setClient);
  }, [clientId]);

  if (client.status === 'loading') {
    return <Skeleton variant="rectangular" width={200} height={40} />;
  }

  if (client.status === 'error') {
    return <Typography color="error">Error: {client.error}</Typography>;
  }

  if (client.status === 'success' && client.data) {
    return (
      <Link to={`/clients/${clientId}`} style={{ textDecoration: 'none' }}>
        <Box display="flex" alignItems="center" mb={2} >
          <Avatar src="/avatar-person.svg" sizes="70px"/>
          <Box ml={2} >
            <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>{`${client.data?.user?.name} ${client.data?.user?.lastname}`} </Typography>
          </Box>
        </Box>
      </Link>
    );
  }

  return null;
};

export default ClientQuickView;