import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const TypingAnimation = () => {

  return (
    <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',marginTop: 2 }}>
      <CircularProgress size={24} thickness={5} />
    </Box>
  );
};

export default TypingAnimation;
