import React, { useRef, useEffect, ReactFragment, RefObject, MutableRefObject } from 'react';
import * as d3 from 'd3';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import './heartShape.css';
import { Box } from '@mui/system';

type HeartShapeProps = {
  x: number,
  y: number,
  chartRef: MutableRefObject<HTMLDivElement>,
}

const HeartShape = ({ x = 0, y = 0, chartRef }: HeartShapeProps) => {
  const papa: string = 'M6.76507 5.23486C6.06634 4.53613 4.93347 4.53613 4.23474 5.23486C3.53601 5.9336 3.53601 7.06646 4.23474 7.7652L7.49991 11.0304L10.7651 7.76519C11.4638 7.06646 11.4638 5.9336 10.7651 5.23486C10.0663 4.53613 8.93347 4.53613 8.23474 5.23486L7.49991 5.9697L6.76507 5.23486Z';
  const rect = chartRef.current?.getBoundingClientRect();
  const svgDimensions = { width: 25, height: 25 };
  /*
  d3.select(chartRef.current)
    ?.append("svg")
    .attr("width", 300)
    .attr("height", 300)
    .style("position", "absolute")
    .style("transform", `translate(${x}, ${y}) scale(0.1) rotate(-135deg)`)
    .append("path")
    .attr("fill", `blue`)
    .attr("d", papa);

  */
// have in account the heigt and width of the heart shape. to the origin.
  console.log({ left: x - svgDimensions.width/2, top: y - svgDimensions.height/2 })
  return (
    <Box position="absolute" style={{ top: 0, left: 0 }}>
      <FavoriteOutlinedIcon className={'heart2'} style={{ position: "absolute", left: x - svgDimensions.width/2, top: y - svgDimensions.height/2 }} />
    </Box>
  );
};

export default HeartShape;
