import React, { ReactElement, FC, useContext, useState, useEffect } from "react";
import { Box, Typography,Container, Button } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import { Link, Navigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import '../i18n/config';
import { useTranslation } from "react-i18next";

const Home: FC<any> = (): ReactElement => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // This timeout simulates a loading process and fades in the content after 500 ms
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);


  if (user.id) {
    return <Navigate to="about" />;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ backgroundImage: 'url(/stocks/ai_gym1.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center',  width: '100%', height: '100vh', position: 'absolute', top: 0 }}>
      <Box width={['100%', '1200px']} py={[4, 0]} height={['auto', '800px']} sx={{ background: 'rgba(0,0,0, 0.7)' }} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box
          component="img"
          sx={{
            width: 300,
          }}
          src="/wizfit_white.png"
        />
        <Box my={[2, 4]} display="none" justifyContent="space-around" width={['100%', '400px']}>
          <Link to="signup">
            <Button  variant="contained" color="primary" size="large">
              {t('home.join')}
            </Button>
          </Link>
          <Link to="sign-in">
            <Button  variant="outlined" color="primary" size="large" sx={{ backgroundColor: 'white' }}>
              {t('home.sign_in')}
            </Button>
          </Link>
        </Box>
        <Box my={[2, 4]} display="flex" justifyContent="space-around" width={['100%', '400px']}>
          <Link to="single-planify">
            <Button  variant="contained" color="primary" size="large">
              {t('home.try_free')}
            </Button>
          </Link>
          <Link to="signup">
            <Button  variant="outlined" color="primary" size="large" sx={{ backgroundColor: 'white' }}>
              {t('home.i_want')}
            </Button>
          </Link>
        </Box>
        <Box>
          <Typography variant="h3" color="white">
            It's not <b>magic</b>, it's <b>evolution</b>
          </Typography>
        </Box>
        <Box mt={2}>
          <Link to="sign-in"><Typography color="white" sx={{ textDecoration: 'underline'}}> ¡Ya tengo cuenta!</Typography></Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;