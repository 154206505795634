import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Button } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { UserContext } from '../../contexts/userContext';
import { WorkspacePremiumOutlined } from '@mui/icons-material';
import ClientGoalAndPreferences from '../common/ClientGoalAndPreference';
import { useTranslation } from 'react-i18next';

const Step7: React.FC = () => {
  const { user } = useContext(UserContext);
  const [mainGoal, setMainGoal] = useState<string>('');
  const { setData, data } = useWizard();
  const [exercisePreference, setExercisePreference] = useState<string>('');
  const { t } = useTranslation();

  const premium = user?.id ? true : false;
  const handleMainGoalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMainGoal((event.target as HTMLInputElement).value);
  };

  const handlePreferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExercisePreference((event.target as HTMLInputElement).value);
  }

  useEffect(() => {
    setData({ ...data, mainGoal, exercisePreference})
  }, [mainGoal, exercisePreference]);

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t('WizardStep7.trainingGoalKnow')}
      </Typography>
      <ClientGoalAndPreferences mainGoal={mainGoal} exercisePreference={exercisePreference} premium={premium} onMainGoalChange={handleMainGoalChange} onExercisePreferenceChange={handlePreferenceChange} /> 

      {!premium && (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" flexDirection="column" mt={['50px']} alignItems="center" maxWidth={['100%', '300px']} padding={[3, 4]} borderRadius={'20px'} >
            <Typography variant="body1" textAlign="center" fontWeight="bold" sx={{ marginBottom: 3 }}>
            {t('WizardStep7.makeyourselfPremium')}
            </Typography>
            <a href="https://wizfit.ai/inicio/#precios" target="_blank" rel="noreferrer">
              <Button variant="outlined" sx={{ paddingTop: '5px'}}>
              {t('WizardStep7.seePremiumPrices')}
              </Button>
            </a>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Step7;
