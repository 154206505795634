import React, { ReactElement, FC, useEffect, useState, useRef, useContext } from "react";
import { Box, Button, Typography, Alert, CircularProgress, AlertTitle, Grow, IconButton, ListItemText, AccordionSummary, Accordion, AccordionDetails, Chip, List, ListItem, ListItemAvatar, ListItemButton, Badge, LinearProgress } from "@mui/material";
import RoomIcon from '@mui/icons-material/Room';
import api, { MultipleTraining, ProcessStatus, WorkoutWeek } from "../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import TrainingProgramDetailsComponent from "../components/TrainingDetails";
import { PageTitle } from "../components/PageTitle";
import { UserContext } from "../contexts/userContext";
import WorkoutaTableComponent from "../components/chat/WorkoutTableComponent";
import { ArrowBack, CalendarToday, ErrorOutline, HailOutlined, ViewWeek } from "@mui/icons-material";
import dayjs from "dayjs";
import WorkoutDialogEmail from "../components/WorkoutDialogEmail";
import DownloadButton from "../components/common/DownloadButton";
import UploadButton from "../components/common/UploadButton";
import { PageTitleWithBack } from "../components/common/PageTitleWithBack";
import { useTranslation } from "react-i18next";


type MultipleTrainingsState = {
  data?: MultipleTraining,
  status: string,
  error?: string | boolean,
}

const MultipleTrainingsPage: FC<any> = (): ReactElement => {
  const containerRef = useRef<any>();
  const { id = '' } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [multipleTrainings, setMultipleTrainings] = useState<MultipleTrainingsState>({ status: "initial" });
  const isCreate = id === 'add';
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(1);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [workouts, setWorkouts] = useState<Array<WorkoutWeek>>([]);
  
  const getMultipleTrainings = async (id: number, last = false) => {
    setMultipleTrainings({ status: "loading" })
    const multipleTrainings = await api.multipleTrainings.getById(id);
    if (multipleTrainings.success && multipleTrainings.data) {
      const data: MultipleTraining = multipleTrainings.data;
      const currentCompleted = (multipleTrainings.data.currentCompleted);

      setPercentage(currentCompleted / multipleTrainings.data.totalTrainings);
      if (data.status === ProcessStatus.FINISHED) {
        if (errors.length !== data.errors.length) {
          setErrors(data.errors);
        }
        if (workouts.length !== data.workoutWeeks.length) {
          setWorkouts(data.workoutWeeks);
        }
        if (!last) {
          console.log('ultimoooo')
          setTimeout(() => getMultipleTrainings(id, true), 3000);
          return;
        }
console.log('ultimo seteo', data)
        setMultipleTrainings({ data, status: "loading" });
        
      }else {

        setErrors(data.errors);
       // getWorkoutWeeks(id);
        setTimeout(() => getMultipleTrainings(id), 3000);
        setWorkouts(data.workoutWeeks);
  
        if (multipleTrainings.data !== data) {
          console.log('actualizacion de data sin finalizar', data)
          setMultipleTrainings({ data, status: "success" });
        }
      }
    } else {
      setMultipleTrainings({ status: "error", error: multipleTrainings.error })
    }
  }
  const handleUploadFile = async (input: any) => {
    setMultipleTrainings({ status: 'loading' });
    const res = await api.multipleTrainings.create(input.target?.files[0]);
    if (res.success) {
      navigate(`/multiple-trainings/${res.data.id}`);
    }else {
      setMultipleTrainings({ status: 'error', error: res.error });
    }
  }

  const handleGoBack = () => navigate('/multiple-trainings');


  useEffect(() => {
    if (!isCreate) {
      getMultipleTrainings(parseInt(id));
    }
  }, [id]);
  
  return isCreate ? (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
    >
      <PageTitle title={t('MultipleTrainings.title')} />
      <Box mb={2} display="flex" flexDirection="column" gap={1}>
        <Box>
          <Typography variant="body1">{t('MultipleTrainings.downloadFile')}</Typography>
          <DownloadButton href="https://storage.googleapis.com/domav2-clases/domav2-images/multiple_trainings_test.xlsx" label={t('MultipleTrainings.downloadLabel')} />
        </Box>
        <Box mt={2}>
          <Typography variant="body1">{t('MultipleTrainings.completeAndUpload')}.</Typography>
          <UploadButton onSelect={handleUploadFile} status={multipleTrainings.status}/>
        </Box>
        {multipleTrainings.status === 'error' && (
          <Alert severity="error">
            <AlertTitle>{multipleTrainings.error === 'file' ? t('MultipleTrainings.fileShouldBeExcel') : `${t('MultipleTrainings.inconvinient')} ${multipleTrainings.error}`}</AlertTitle>
          </Alert>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
    >
      <PageTitleWithBack title={t('MultipleTrainings.planificationBatch')} onBack={handleGoBack} />
      <Box mb={2} display="flex" flexDirection="column" gap={1}>
        <Typography variant="h4">{multipleTrainings.data?.name}</Typography>
        <Box display="flex" gap={1}>
          <CalendarToday /><Typography variant="body1"><b>{t('MultipleTrainings.created')}</b> {dayjs(multipleTrainings.data?.createdAt).format('MM/DD/YYYY HH:mm')}</Typography>
        </Box>
        <Box>
          {multipleTrainings.data?.status && (
            <Typography variant="body1">
              {`${workouts?.length}/${multipleTrainings.data?.totalTrainings} ${t('MultipleTrainings.generatedTrainings')}`}
            </Typography>
          )}
        </Box>
        {multipleTrainings.data?.status !== ProcessStatus.FINISHED && (
          <Box>
            <Alert severity="info">
              <AlertTitle>{t('MultipleTrainings.generatingDontWorry')}</AlertTitle>
            </Alert>
            <Box >
              <LinearProgress variant="determinate" value={percentage * 100} />
            </Box>
          </Box>
        )}
        {percentage < 1 && multipleTrainings.status === 'loading' && (
          <Alert severity="info">
            <AlertTitle>{t('MultipleTrainings.magicStarting')}</AlertTitle>
            {multipleTrainings.data?.failed && (<AlertTitle>{t('MultipleTrainings.retrying')}</AlertTitle>)}
            <AlertTitle>{Math.ceil(percentage * 100)}% {t('MultipleTrainings.completed')}</AlertTitle>
          </Alert>
        )}
        <Accordion TransitionProps={{ unmountOnExit: true }} >
          <AccordionSummary>
            <Badge badgeContent={errors.length} color="error">
              <ErrorOutline color="action" />
            </Badge>
            <Typography variant="subtitle1" sx={{ marginLeft: 3 }}>{t('MultipleTrainings.errors')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {errors.map((e, index) => (
              <Alert severity="error" key={index}>{e}</Alert>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion TransitionProps={{ unmountOnExit: true }} >
          <AccordionSummary>
            <Badge badgeContent={workouts.length} color="success">
              <HailOutlined color="action" />
            </Badge>
            <Typography variant="subtitle1" sx={{ marginLeft: 3 }}>{t('MultipleTrainings.planifications')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {workouts.map((w, index) => (
                <ListItem key={index} secondaryAction={
                  <Link to={`/workoutweeks/${w.id}`} >
                    {t('MultipleTrainings.see')}
                  </Link>
                }>
                  
                  <ListItemAvatar>
                    <HailOutlined />
                  </ListItemAvatar>
                  <ListItemText primary={w.name} secondary={t('WorkoutWeeks.daysPerWeek', { days: w.days })}>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        

      </Box>
    </Box>
  );
};

export default MultipleTrainingsPage;