import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { WorkoutWeekDraft } from '../../api';
import { Box } from '@mui/system';
import { repsToString } from '../../utils/json.extract';


type WorkoutTableProps = {
  workoutWeek?: WorkoutWeekDraft,
  width?: any,
}

export default function WorkoutaTableComponent({ workoutWeek, width }: WorkoutTableProps) {
  const w = width ? width : `${window.innerWidth - 20}px`;
  console.log("workout week", workoutWeek)
  if (!workoutWeek?.planification) {
    return <Box>-</Box>;
  }
  return (
    <TableContainer component={Paper} >
      <Table sx={{ width: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', minWidth: 80 }} padding="normal">Día</TableCell>
            <TableCell sx={{ fontWeight: 'bold'}} padding="normal">Ejercicio</TableCell>
            <TableCell sx={{ fontWeight: 'bold'}} padding="normal">Series</TableCell>
            <TableCell sx={{ fontWeight: 'bold'}} padding="normal">Repeticiones</TableCell>
            <TableCell sx={{ fontWeight: 'bold'}} padding="normal">Observaciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workoutWeek.planification.map((day, index) => (
            day.exercises.map((e, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell padding="normal" component="th" scope="row">
                  {day.day}
                </TableCell>
                <TableCell padding="normal" sx={{ minWidth: 150 }}>{e.name}</TableCell>
                <TableCell padding="normal">{e.sets}</TableCell>
                <TableCell padding="normal">{repsToString(e.reps)}</TableCell>
                <TableCell padding="normal">{e.muscle_group}</TableCell>
              </TableRow>
            ))
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}