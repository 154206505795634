import React from 'react';
import { Typography } from "@mui/material"
import { Box } from "@mui/system"

export const PageTitle = ({ title = '' }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "whitesmoke",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: 50,
      }}
      mb={[3]}
    >
      <Typography variant="h3">{title}</Typography>
      
    </Box>
  )
}
