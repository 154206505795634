import React, { useRef, useEffect, ReactFragment, RefObject, useState, MutableRefObject, useContext } from 'react';
import HeartShape from '../components/HeartShape';
import api, { TrainingSession } from '../api';
import { SessionContext, TrainingData } from '../contexts/sessionContext';

type HeartRateContainerProps = {
  chartRef: MutableRefObject<HTMLDivElement>,
  xScale: Function,
  yScale: Function,
  sessionId: string,
  roomId: string,
  bandCode: string | undefined,
}

const HeartRateContainer = ({ chartRef, xScale, yScale, sessionId, roomId, bandCode }: HeartRateContainerProps) => {
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const { updateTrainingData } = useContext(SessionContext)
  const getTrainingSession = async (id: string, roomId: string, bandCode: string) => {
    const tsResponse = await api.trainingSessions.data(id, roomId, bandCode);
    if (tsResponse.success && tsResponse.data) {
      const data: TrainingData = tsResponse.data;
      const x = data.currentData.length;
      const y = data.currentEffort[data.currentEffort.length-1];
      setCoords({ x: xScale(x), y: yScale(y) });
      console.log('hr container',data)
      updateTrainingData({
        calories: data.calories,
        currentData: data.currentData,
        currentEffort: data.currentEffort,
        points: data.points,
        rank: data.rank,
        score: data.score
      })
    }
  }
  
  
  useEffect(() => {
    let interval: NodeJS.Timer;
    if (sessionId && roomId && bandCode) {
      getTrainingSession(sessionId, roomId, bandCode)
      interval = setInterval(() => {
        getTrainingSession(sessionId, roomId, bandCode)
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [sessionId, roomId, bandCode])
  
  return (
    <HeartShape x={coords.x} y={coords.y} chartRef={chartRef} />
  )
};

export default HeartRateContainer;
