import React, { useContext, useState } from 'react';
import { TextField, Button, Box, Paper, Typography, Container, Alert, AlertTitle, TextFieldProps, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Checkbox, Slide, Zoom } from '@mui/material';
import api from '../api';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import { Image } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const MyTextField = (props: TextFieldProps) => (
  <TextField
    variant="filled"
    InputProps={{ style: { backgroundColor: 'background' }}}
    margin="normal"
    fullWidth
    {...props}
  />
)
const INITIAL_STATE = {
  gymname: '',
  logo: '',
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  password: '',
  passwordConfirmation: '',
  birthdate: '',
  gender: 'MASCULINE',
  accept: true,
};

type ResultState = {
  status: string;
  error: string;
  message?: string;
}

const SignupContainer = () => {
  const [values, setValues] = useState(INITIAL_STATE);
  const [result, setResult] = useState<ResultState>({ status: 'initial', error: '', message: '' });
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const handleSignup = async (e: any) => {
    e.preventDefault();
    if (!(values.accept && values.gymname && values.email && values.firstname && values.lastname && !passwordError)) { 
      setResult({ status: 'error', error: 'incomplete' })
      return;
    }
    setResult({ status: 'loading', error: '' })
    const res = await api.auth.signup(values)
    
    if (res.success) {
      setResult({ status: 'success', error: '' });
      setTimeout(() => navigate("/sign-in"), 2000);
    }else {
      const error = Array.isArray(res.error) ? res.error[0].split(' ') : res.error;
      setResult({ status: 'error', error: error.length ? error[0] : 'generic' })
    }
  };

  const handleTermsAndConditions = (e: any) => {
    console.log(e)
    setValues((values) => ({ ...values, accept: e.target.checked ? true : false}))
  }

  const handleInput = (input: string) => (e: any) => setValues((values) => ({ ...values, [input]: e.target.value}));
  const passwordError = (values.password.length && values.passwordConfirmation.length && values.password !== values.passwordConfirmation);
  
  if (user.id) {
    return <Navigate to="about" />;
  }
  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ backgroundImage: 'url(/stocks/ai_gym1.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center',  width: '100%', height: '100vh', position: 'absolute', top: 0, overflow: ['scroll', ''] }}>
      <Box width={['100%', '1200px']} py={[4, 0]} height={['1000px', '800px']} sx={{ background: 'rgba(0,0,0, 0.7)' }} display="flex" flexDirection="column" alignItems="center" justifyContent="center"> 
        <Box sx={{ width: ["100%"], margin: '0 auto' }}>
          <Slide direction="left" in={true}>
            <Paper elevation={3} sx={{ padding: '0', margin: ['0', '90px auto'], background: 'transparent', display: 'flex', flexDirection: ['column-reverse', 'row'], alignItems: 'center' }}>
              <Box flex="6" sx={{ mb: ['0', '-7px'], width: ['100%', '550px'] }} position="relative">
                <Typography variant="h5" sx={{ position: 'absolute', top: [95, 160], left: [120, 275], color: 'white', textAlign: 'center', width: 210, transform: 'skew(-25deg, 7deg) scaleY(1)', textShadow: '4px 4px 8px rgba(0,0,0,0.8)'}}>{values.gymname}</Typography>
                <img style={{ borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }} width="100%" src="/signup_2.jpg"/>
                <Box sx={{ position: 'fixed', bottom: 10, zIndex: 99 }}>
                  {result.error && (
                    <Alert variant="filled" severity="error" onClick={(e: any) => setResult({ error: '', status: 'initial' })}>
                      {result.error === 'generic' && <AlertTitle>{t('SignUp.errorGeneric')}</AlertTitle>}
                      {result.error === 'email' && <AlertTitle>{t('SignUp.errorInvalidEmail')}</AlertTitle>}
                      {result.error === 'phone' && <AlertTitle>{t('SignUp.invalidPhone')}</AlertTitle>}
                      {result.error === 'existent' && <AlertTitle>{t('SignUp.errorExistentEmail')}</AlertTitle>}
                      {result.error === 'incomplete' && <AlertTitle>{t('SignUp.completeAll')}</AlertTitle>}
                    </Alert>
                  )}
                  {result.status === 'success' && (
                    <Alert variant="filled" severity="success">
                      <AlertTitle>{t('SignUp.success')}</AlertTitle>
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box flex="4" px={[1, 3]} mt={['450px', '0']}>
                <Box display="flex" flexDirection="row-reverse" justifyContent="space-between" alignItems="center">
                  <img src="/logo_icon.png" width="60px" />
                  <Typography component="h1" variant="h5" color="white">
                  {t('SignUp.register')} <br />
                    {/*<Typography variant="subtitle1">{t('SignUp.noCreditCardNeeded')}</Typography>*/}
                  </Typography>
                </Box>
                <form onSubmit={handleSignup} style={{ width: '100%', marginTop: '16px' }}>
                  <MyTextField
                    label={t('SignUp.gymName')}
                    name="gymname"
                    value={values.gymname}
                    onChange={handleInput('gymname')}
                  />
                  <MyTextField
                    label={t('SignUp.email')}
                    name="email"
                    value={values.email}
                    onChange={handleInput('email')}
                  />
                  <Box display="flex" flexDirection={['column', 'row']}>
                    <MyTextField
                      label={t('SignUp.name')}
                      name="firstname"
                      sx={{ flex: 1 }}
                      value={values.firstname}
                      onChange={handleInput('firstname')}
                    />
                    <MyTextField
                      label={t('SignUp.lastname')}
                      name="lastname"
                      sx={{ flex: 1, ml: [0, 1] }}
                      value={values.lastname}
                      onChange={handleInput('lastname')}
                    />
                  </Box>
                  <MyTextField
                    label={t('SignUp.phone')}
                    name="phone"
                    value={values.phone}
                    onChange={handleInput('phone')}
                  />
                  <Box display="flex" flexDirection={['column', 'row']}>
                    <MyTextField
                      label={t('SignUp.password')}
                      name="password"
                      value={values.password}
                      type="password"
                      sx={{ flex: 1 }}
                      onChange={handleInput('password')}
                    />
                    <MyTextField
                      label={t('SignUp.passwordConfirmation')}
                      name="passwordConfirmation"
                      sx={{ flex: 1, ml: [0, 1] }}
                      value={values.passwordConfirmation}
                      onChange={handleInput('passwordConfirmation')}
                      type="password"
                      InputProps={{ error: passwordError ? true : false, style: { backgroundColor: 'background' } }}
                    />
                  </Box>
                  <Box  sx={{ color: 'white'}}>
                    <FormControl>
                      <FormLabel >{t('SignUp.gender')}</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        row={true}
                        value={values.gender}
                      >
                        <FormControlLabel value="FEMENINE" control={<Radio onClick={handleInput('gender')}/>} label={t('SignUp.female')} />
                        <FormControlLabel value="MASCULINE" control={<Radio onClick={handleInput('gender')}/>} label={t('SignUp.male')} />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  
                  <FormControlLabel
                    value="accept"
                    sx={{ color: 'white'}} defaultChecked={true}
                    control={<Checkbox value={values.accept} defaultChecked onClick={handleTermsAndConditions} />}
                    label={<p>Acepto <a href="#" target="_blank" style={{ color: 'white' }}>{t('SignUp.termsConditions')}</a></p>}
                    labelPlacement="end"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={result.status === 'loading' }
                    style={{ margin: '24px 0 16px' }}
                  >
                    {t('SignUp.registerMe')}
                  </Button>
                  
              
                </form>
                <Box mt={2} display="flex" justifyContent="flex-start" width="100%">
                  <Link to="/sign-in">
                    <Button variant="text">
                    {t('SignUp.iHaveAccount')}
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Paper>
          </Slide>
        </Box>
      </Box>

    </Box>
  );
};

export default SignupContainer;
