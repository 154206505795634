import React from 'react';
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import WorkspacePremiumOutlined from '@mui/icons-material/WorkspacePremiumOutlined';
import { useTranslation } from 'react-i18next';

interface ClientGoalAndPreferencesProps {
  mainGoal: string;
  exercisePreference: string;
  premium: boolean;
  onMainGoalChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onExercisePreferenceChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  direction?: string;
}

const ClientGoalAndPreferences: React.FC<ClientGoalAndPreferencesProps> = ({
  mainGoal,
  exercisePreference,
  premium,
  onMainGoalChange,
  onExercisePreferenceChange,
  direction = 'column',
}) => {
  const dir = direction === 'row' ? 'row' : 'column';

  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection={['column', dir]} gap={2} width="100%">
      <Box flex="1">
        <Typography variant="subtitle1" color="grey" sx={{ marginTop: 2 }}>
          {t('ClientGoalAndPreference.trainingGoal')}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="mainGoal"
            name="mainGoal"
            value={mainGoal}
            onChange={onMainGoalChange}
            sx={{ flexDirection: 'column' }}
          >
            <FormControlLabel value={t('ClientGoalAndPreference.hipertrophyValue')} control={<Radio />} label={t('ClientGoalAndPreference.hipertrophyLabel')} />
            <FormControlLabel value={t('ClientGoalAndPreference.forceValue')} control={<Radio />} label={t('ClientGoalAndPreference.forceLabel')} />
            <FormControlLabel value={t('ClientGoalAndPreference.resistanceValue')} control={<Radio />} label={t('ClientGoalAndPreference.resistanceLabel')} />
            <FormControlLabel value={t('ClientGoalAndPreference.loseWeightValue')} control={<Radio />} label={t('ClientGoalAndPreference.loseWeightLabel')} />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box flex="2">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1" color="grey" sx={{ marginTop: 2 }}>
          {t('ClientGoalAndPreference.trainingPreferences')}
          </Typography>
          {!premium && <WorkspacePremiumOutlined sx={{ ml: 2, mt: 1 }} />}
        </Box>

        <TextField
          multiline
          rows={4}
          placeholder={t('ClientGoalAndPreference.exampleNeedTrainingForAthlete')}
          value={exercisePreference}
          disabled={!premium}
          fullWidth
          sx={{ marginTop: 2 }}
          onChange={onExercisePreferenceChange}
        />
      </Box>
    </Box>
  );
};

export default ClientGoalAndPreferences;