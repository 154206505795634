import { Button, FormControl, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState } from 'react';
import api, { ProgramStep, Room } from "../api";
import { UserContext } from '../contexts/userContext';
import { EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const UserFormContainer = () => {
  const { user, updateUser, getUser } = useContext(UserContext);
  const [data, setData] = useState({ nick: user.client?.nick });
  const [isEditing, setIsEditing] = useState(false);
  const [editing, setEditing] = useState({ status: 'initial', error: '' })
  const { t } = useTranslation();

  const handleSave = async () => {
    const res = await api.clients.update(user.clientId, data);
    if (res.success) {
      setIsEditing(false);
      getUser();
    }
  }
  
  return (
    <Box mt={1} mb={2}>
      {!isEditing ? (
        <Box display="flex">
          <Box display="flex" flex="10">
            <Typography variant="body1" flex={1} component="span">{t('UserForm.yourNickIs', { nick: user.client?.nick})}</Typography>
          </Box>
          <Box display="flex" flex="1">
            <EditOutlined color="secondary" fontSize="medium" onClick={() => setIsEditing(true)} />
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" rowGap={2}>
          <FormControl>
            <TextField value={data.nick} label="Nick" onChange={(evt) => setData((data) => ({ ...data, nick: evt.target.value }))} />
          </FormControl>
          <FormControl>
            <Button variant="contained" disabled={editing.status === 'loading'} onClick={handleSave}>
              {t('UserForm.save')}
            </Button>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default UserFormContainer;
