import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Payment } from '../api';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { formatDate, formatDateTime } from '../utils/dates';
import { Button, Chip, ChipOwnProps, Skeleton, TableFooter, Typography, useTheme } from '@mui/material';
import { formatMoney } from '../utils/times';


type WorkoutTableProps = {
  payments: Payment[],
  status: string,
  error?: string,
  onNext?: () => void,
  onPrevious?: () => void, 
}

const StatusLabel = ({ payment }: { payment: Payment }) => {
  const { t } = useTranslation();
  let label = '';
  let color: ChipOwnProps["color"] = 'success';
  switch (payment.status) {
    case 'succeeded':
      label = payment.chargeRefunded ? 'refunded' : 'succeeded';
      color = payment.chargeRefunded ? 'info' : 'success';
      break;
    case 'canceled':
    default:
      label = 'canceled';
      color = 'info';
      break;
  }
  return (
    <Chip size='small' label={t(`PaymentsTable.${label}`)} color={color} />
  );
}

export default function PaymentsTableComponent({ payments, status, error, onNext, onPrevious }: WorkoutTableProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <TableContainer component={Paper} >
      <Table sx={{ width: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', minWidth: 80 }} padding="normal">{t('PaymentsTable.date')}</TableCell>
            <TableCell sx={{ fontWeight: 'bold'}} padding="normal">{t('PaymentsTable.amount')}</TableCell>
            <TableCell sx={{ fontWeight: 'bold'}} padding="normal">{t('PaymentsTable.status')}</TableCell>
            <TableCell sx={{ fontWeight: 'bold'}} padding="normal">{t('PaymentsTable.description')}</TableCell>
            <TableCell sx={{ fontWeight: 'bold'}} padding="normal">{t('PaymentsTable.invoice')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {status === 'loading' && (
            <>
              <TableRow>
                <TableCell colSpan={5} sx={{ p: 1 }}>
                  <Skeleton variant="rectangular" width={'100%'} height={40} animation="wave" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} sx={{ p: 1 }}>
                  <Skeleton variant="rectangular" width={'100%'} height={40} animation="wave" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} sx={{ p: 1 }}>
                  <Skeleton variant="rectangular" width={'100%'} height={40} animation="wave" />
                </TableCell>
              </TableRow>
            </>
          )}
          {payments.map((payment, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="normal" component="th" scope="row">
                <b>{formatDateTime(payment.created)}</b>
              </TableCell>
              <TableCell padding="normal" sx={{ minWidth: 150 }}>
                {formatMoney(payment.amount/100, payment.currency)}
                <Typography variant="body1" component="span" color={'GrayText'} sx={{ ml: 1, textTransform: 'uppercase' }}>{payment.currency}</Typography>
              </TableCell>
              <TableCell padding="normal">
                <StatusLabel payment={payment} />
              </TableCell>
              <TableCell padding="normal">{payment.description}</TableCell>
              <TableCell padding="normal">{payment.invoice ? t('PaymentsTable.yes') : t('PaymentsTable.na')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter sx={{ textAlign: 'right' }}>
          <TableRow>
            <TableCell colSpan={3}>
              {status === 'error' && <Box color="error.main">{error}</Box>}
            </TableCell>
            <TableCell colSpan={2} sx={{ textAlign: 'right' }}>
              {onPrevious && <Button variant="text" onClick={onPrevious}>{t('PaymentsTable.previous')}</Button>}
              {onNext && <Button variant="text" onClick={onNext}>{t('PaymentsTable.next')}</Button>}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}