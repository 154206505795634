import React, { ReactElement, FC, useEffect, useContext, useState } from "react";
import { Box } from "@mui/material";
import api, { Room, TrainingSession } from "../api";
import RoomsList from "../components/RoomsList";

type RoomsState = {
  data?: Room[],
  status: string,
  error?: string,
}
type ContainerProps = {
};

const TrainingsAtRoomsContainer: FC<ContainerProps> = (): ReactElement => {
  const [rooms, setRooms] = useState<RoomsState>({ data: [], status: 'initial' });

  const getRooms = async () => {
    setRooms({ data: [], status: 'loading' });
    const res = await api.rooms.getWithTrainings()

    if (res.success) {
      setRooms({ data: res.data, status: 'success' });
    }else {
      setRooms({ data: [], status: 'error', error: res.error })
    }
  }

  useEffect(() => {
    getRooms();
  }, []);

  return (
    <RoomsList data={rooms.data} status={rooms.status} error={rooms.error} />
  );
};

export default TrainingsAtRoomsContainer;