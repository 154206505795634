import React, { ReactElement, FC, useState, useContext } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import TabPanel from "../components/common/TabPanel";
import TrainingClasses from "./TrainingClasses";
import TrainingsAtRoomsContainer from "../containers/TrainingsAtRoomsContainer";
import { UserContext } from "../contexts/userContext";
import { useTranslation } from 'react-i18next';

const TAB_ROOMS = 1;
const TAB_ALL = 0;

const ClassesAndRoomsPage: FC<any> = (): ReactElement => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(TAB_ALL);
  const { user } = useContext(UserContext);
  const isProfessor = user.role === 'PROFESSOR';

  const handleChange = (_: any, newNumber: number) => setTab(newNumber);

  return isProfessor ? (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label={t('ClassesAndRoomsPage.createdClasses')} />
          <Tab label={t('ClassesAndRoomsPage.activeClasses')} />
        </Tabs>
      </Box>
      <Box pb={3}>
        <TabPanel value={tab} index={TAB_ALL}>
          <TrainingClasses />
        </TabPanel>
        <TabPanel value={tab} index={TAB_ROOMS}>
          <TrainingsAtRoomsContainer />
        </TabPanel>
      </Box>
    </Box>
  ) : (
    <Box>
      <PageTitle title={t('ClassesAndRoomsPage.availableClasses')} />
      <TrainingClasses />
    </Box>
  );
};

export default ClassesAndRoomsPage;