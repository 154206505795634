import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

type CustomSearchProps = {
  placeholder: string
  onSearch: any

}
const SearchField = (props: CustomSearchProps) => {

  const { t } = useTranslation();
  const { placeholder = t('Search.search'), onSearch  = () => {}} = props;
  const [search, setSearch] = useState("");

  const handleClick = () => {
    onSearch(search);
  };
  const handleSubmit = (evt: any) => {
    if (evt.key === "Enter") {
      handleClick();
    }
  }
  return (
    <Box>
      <Box
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          onChange={(evt) => setSearch(evt.target.value)}
          onKeyDown={handleSubmit}
          inputProps={{  }}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleClick}>
          <SearchIcon />
        </IconButton>
      </Box>
      <Divider />
    </Box>
  );
}

export default  SearchField;
