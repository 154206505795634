import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

interface PartyExplosionProps {
  duration?: number; // Duration in milliseconds
  numberOfPieces?: number; // Number of confetti pieces
}

const PartyExplosion: React.FC<PartyExplosionProps> = ({ duration = 5000, numberOfPieces = 200 }) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const [isExploding, setIsExploding] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExploding(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <>
      {isExploding && <Confetti width={width} height={height} numberOfPieces={numberOfPieces} />}
    </>
  );
};

export default PartyExplosion;