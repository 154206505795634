import React, { ReactElement, FC, useEffect, useState, useRef, useContext, useTransition } from "react";
import { Box, Button, Typography, Alert, IconButton, Avatar } from "@mui/material";
import RoomIcon from '@mui/icons-material/Room';
import api, { WorkoutWeek } from "../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import TrainingProgramDetailsComponent from "../components/TrainingDetails";
import { PageTitle } from "../components/PageTitle";
import { UserContext } from "../contexts/userContext";
import WorkoutaTableComponent from "../components/chat/WorkoutTableComponent";
import { AccountCircleOutlined, ArrowBack, CalendarToday, ViewWeek } from "@mui/icons-material";
import dayjs from "dayjs";
import WorkoutDialogEmail from "../components/WorkoutDialogEmail";
import { PageTitleWithBack } from "../components/common/PageTitleWithBack";
import { useTranslation } from "react-i18next";


type WorkoutWeekState = {
  data?: WorkoutWeek,
  status: string,
  error?: string | boolean,
}

const WorkoutWeekPage: FC<any> = (): ReactElement => {
  const containerRef = useRef<any>();
  const { workoutWeekId = '' } = useParams();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [workoutWeek, setWorkoutWeek] = useState<WorkoutWeekState>({ status: "initial" });


  const handleGoBack = () => navigate(-1);
  const getWorkoutWeek = async (id: number) => {
    setWorkoutWeek({ status: "loading" })
    const workoutWeek = await api.workoutWeeks.get(id);
    if (workoutWeek.success) {
      const data: WorkoutWeek | undefined = workoutWeek.data;
      
      setWorkoutWeek({ data, status: "success" });
    } else {
      setWorkoutWeek({ status: "error", error: workoutWeek.error })
    }
  }

  const handleEditAndCreate = () => {
    navigate(`/multiple-trainings-draft/${workoutWeek.data?.multipleTrainingId}`);
  }


  useEffect(() => {
    getWorkoutWeek(parseInt(workoutWeekId));
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
    >
      <Box mb={2} display="flex" flexDirection="column" gap={1}>
        <PageTitleWithBack title={workoutWeek.data?.name} onBack={handleGoBack} />
        <Box display="flex" gap={1}>
          <CalendarToday /><Typography variant="body1"><b>{t('WorkoutWeek.created')}</b> {dayjs(workoutWeek.data?.createdAt).format('MM/DD/YYYY HH:mm')}</Typography>
        </Box>
        <Box display="flex" gap={1}>
          <ViewWeek /><Typography variant="body1"><b>{t('WorkoutWeek.days')}</b>{workoutWeek.data?.days}</Typography>
        </Box>
        {workoutWeek.data?.client && (
          <Box display="flex" gap={1}>
            <AccountCircleOutlined /><Typography variant="body1"><b>{t('WorkoutWeek.client')} </b> 
              <Link to={`/clients/${workoutWeek.data?.client.id}`}>
                {workoutWeek.data?.client.user?.name} {workoutWeek.data?.client.user?.lastname}
              </Link>
            </Typography>
          </Box>
        )}
        {workoutWeek.data?.description && (
          <Box mt={2}>
            <Alert severity="info">{workoutWeek.data?.description}</Alert>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <a href={workoutWeek.data?.fileUrl} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
            <Button variant="outlined">{t('WorkoutWeek.seePdf')}</Button>
          </a>
          <Box display="flex" gap={2}>
            <Button disabled={!workoutWeek.data?.multipleTrainingId} onClick={handleEditAndCreate} variant="outlined">{t('WorkoutWeek.editAndCreateNew')}</Button>
            <WorkoutDialogEmail workoutWeekid={workoutWeekId} />
          </Box>
        </Box>
      </Box>
      <WorkoutaTableComponent workoutWeek={workoutWeek?.data?.planification || undefined} width="100%"/>
    </Box>
  );
};

export default WorkoutWeekPage;