import React, { useEffect } from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { CakeOutlined, HeightOutlined, MonitorWeightOutlined } from '@mui/icons-material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { useTranslation } from 'react-i18next';

const Step3 = () => {
  const { setData, data, currentStep } = useWizard();
  const [age, setAge] = React.useState<number>();
  const [weight, setWeight] = React.useState<number|undefined>();
  const [height, setHeight] = React.useState<number>();
  const { t } = useTranslation();

  useEffect(() => {
    setData({ ...data, age, weight, height });
  }, [age, weight, height, currentStep]);
  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t('WizardStep3.title')}
      </Typography>
      <Box mt={2}>
        <TextField
          label={t('WizardStep3.yourAge')}
          type="number"
          value={age !== null ? age : ''}
          onChange={(e) => setAge(e.target.value ? parseInt(e.target.value) : undefined)}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CakeOutlined />
              </InputAdornment>
            ),
          }}
          inputProps={{ min: 0, max: 100 }}
        />
        <Box display="flex" gap={2}>
          <TextField
            label={t('WizardStep3.yourWeightInKg')}
            type="number"
            value={weight !== null ? weight : ''}
            onChange={(e) => setWeight(e.target.value ? parseInt(e.target.value) : undefined)}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MonitorWeightOutlined /> 
                </InputAdornment>
              ),
            }}
            inputProps={{ min: 0, max: 150 }}
          />
          <TextField
            label={t('WizardStep3.yourHeightInCm')}
            type="number"
            value={height !== null ? height : ''}
            onChange={(e) => setHeight(e.target.value ? parseInt(e.target.value) : undefined)}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
              <InputAdornment position="start">
                <HeightOutlined />
              </InputAdornment>
              ),
            }}
            inputProps={{ min: 0, max: 220 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Step3;