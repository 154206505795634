import React from 'react';

const Logo = ({ fillColor = '#FFFFFF', width = '45', height = '45' }) => (
  <svg width={width} height={height} viewBox="0 0 175 163" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M174.043 149.287C165.764 141.993 152.514 132.164 143.605 127.468C106.662 136.877 67.9496 136.877 31.0063 127.468C22.0977 132.132 7.83122 141.993 0.568847 149.303C54.6493 167.847 121.173 167.169 174.043 149.303" fill={fillColor}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M42.5131 121.432L19.3219 1.08634L50.9536 1.08634L57.7156 52.7299C63.7353 38.3988 73.0473 17.225 87.1202 0.150319C95.7705 12.0122 111.49 34.8645 116.46 52.5847L123.206 1.11859L154.773 1.11859L131.695 121.19C122.027 119.634 112.281 118.61 102.5 118.124C99.1757 92.3018 93.4304 75.8243 87.2493 62.2194C80.9391 76.0179 75.2745 92.8828 71.9661 118.172C62.0893 118.615 52.2514 119.693 42.5131 121.4" fill={fillColor}/>
  </svg>

);

export default Logo;

