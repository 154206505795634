import React from 'react';
import EctomorphSVG from '../../icons/ectomorph';
import MesomorphSVG from '../../icons/mesomorph';
import EndomorphSVG from '../../icons/endomorph';
import { Box } from '@mui/material';

const bodyTypeToSVG: { [key: string]: React.FC<{ selected?: boolean; gender?: string; height?: string }> } = {
  mesomorph: MesomorphSVG,
  endomorph: EndomorphSVG,
  ectomorph: EctomorphSVG,
};
  
// Define the component
type BodyTypeProps = {
  bodyType: string;
  gender?: string;
};

const BodyTypeCard: React.FC<BodyTypeProps> = ({ bodyType, gender }) => {
  const SVGComponent = bodyTypeToSVG[bodyType] || MesomorphSVG;
  return <Box sx={{ 'svg': { transform: 'scale(0.9)' }}}>
    <SVGComponent gender={gender} height="180px" />

  </Box>
};

export default BodyTypeCard;