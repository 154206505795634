import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { CloudDownload } from '@mui/icons-material';

type Props = {
  href: string
  label: string
}

export default function DownloadButton({ href, label = 'Descargar' }: Props) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudDownload />}
      >
        {label}
      </Button>
    </a>
  );
}