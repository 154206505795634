import React, { useState } from 'react'
import { Button, IconButton, TextField, Theme, useTheme } from '@mui/material';

import { Box, Paper, Typography, Container, Alert, AlertTitle } from '@mui/material';
import { TextInput } from './TextInput';
import { MessageLeft, MessageRight } from './Message';
import TypingAnimation from './TypingAnimation';
import { Conversation, Program, WorkoutWeek, WorkoutWeekCreate, WorkoutWeekDraft } from '../../api';
import WorkoutaTableComponent from './WorkoutTableComponent';
import dayjs from 'dayjs';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { extractWorkoutWeekFromText } from '../../utils/json.extract';
import { PageTitleWithBack } from '../common/PageTitleWithBack';

const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    paddingBottom: '20px',
    height: '100vh',
    paddingLeft: 0,
    paddingRight: 0,
    
    margin: '0 auto',
  },
  messagesBody: {
    width: '100%',
  }
});

type RequestType = {
  status: string,
  workoutWeek?: WorkoutWeekDraft,
};

type ConversationProps = {
  conversation?: Conversation,
};

type WorkoutDraftState = {
  workoutWeek?: WorkoutWeekDraft
}


type MessageWithPlanification = {
  workoutWeek: WorkoutWeekDraft
  msg: string
}

export default function ConversationReviewComponent({ conversation }: ConversationProps) {
  const theme = useTheme();
  const classes = styles(theme);
  const navigate = useNavigate();
  const [currentWorkoutDisplay, setCurrentWorkoutDisplay] = useState<number | undefined>();

  const handleSeePlanification = (messageId: number) => () => setCurrentWorkoutDisplay(messageId);
  const conversationDate = conversation?.aiMessages.length ? dayjs(conversation?.aiMessages[0].createdAt).format('DD/MM/YYYY HH:mm') : '';

  const messages = conversation?.aiMessages?.length ? conversation.aiMessages : [];
  const handleGoBack = () => navigate('/conversations');
  const planifications: { [k: number]: MessageWithPlanification } = messages.reduce((acc, curr) => {
    // const msg = extractDomaProgramFromText(curr.message);
    // later check if it is a doma or a fit extraction
    const msg = extractWorkoutWeekFromText(curr.message);
    console.log(msg)
    if (msg.workoutWeek) {
      return {
        ...acc,
        [curr.id]: msg,
      };
    }
    return acc;
  }, {});

  return (
    <Box sx={classes.container} width={['auto', '800px']}>

      <PageTitleWithBack title={`Conversación de ${conversation?.user?.name} ${conversation?.user?.lastname}.`} onBack={handleGoBack} />
      <Box>
        <Typography variant="body1">Fecha: {conversationDate}</Typography>
      </Box>
      <Box flex={10} sx={{ width: '100%' }}>
        {messages.map((m, index) =>
          m.sender === 'ASSISTANT' ? (
            <Box key={index}>
              <MessageLeft
                message={planifications[m.id] ? planifications[m.id].msg : m.message}
                timestamp=''
              />
              <Box>
                {planifications[m.id] && (
                  <Box>
                    <Box display="flex" justifyContent="flex-start">
                      <Button variant="contained" onClick={handleSeePlanification(m.id)}> Ver planificación </Button> 
                    </Box>

                    {currentWorkoutDisplay === m.id && (
                      <Box>
                        <Box my={2}>
                          <Typography variant="body1">{planifications[m.id].workoutWeek.reasoning}</Typography>
                        </Box>
                        <WorkoutaTableComponent workoutWeek={planifications[m.id].workoutWeek} />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <MessageRight
              key={index}
              message={m.message}
              timestamp=''
            />    
          ))
        }
      </Box>
    </Box>
  );
}
