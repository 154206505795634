import React from 'react';
import { IconButton, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { ArrowBack } from '@mui/icons-material';

export const PageTitleWithBack = ({ title = '', onBack = () => {} }) => {
  return (
    <Box display="flex" justifyContent="space-around" width="100%">
      <Box display="flex" flex="1">
        <IconButton
          color="inherit"
          sx={{ mr: 0 }}
          onClick={onBack}
        >
          <ArrowBack width="45" height="45" />
        </IconButton>
      </Box>
      <Box flex="10">
        <Typography variant="h3">{title}</Typography>
      </Box>
    </Box>
  )
}
