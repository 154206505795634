import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Alert, TextField } from '@mui/material';
import api from '../api';
import { useTranslation } from 'react-i18next';

type Props = {
  workoutWeekid: string
}

export default function WorkoutDialogEmail({ workoutWeekid }: Props) {
  const [open, setOpen] = React.useState(false);
  const [request, setRequest] = React.useState({ status: 'initial' })
  const [email, setEmail] = React.useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmail = async () => {
    setRequest({ status: 'loading' });
    const res = await api.workoutWeeks.sendEmail(parseInt(workoutWeekid), email);
    if (res.success) {
      setRequest({ status: 'success' });

      setTimeout(() => {
        handleClose();

      setRequest({ status: 'initial' });
      setEmail('');
      }, 1000);
    } else {
      setRequest({ status: 'error' });
    }
    
  }

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {t('WorkoutDialogEmail.sendEmail')}
      </Button>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{
        }}
      >
        <DialogTitle>{t('WorkoutDialogEmail.sendPlanification')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {t('WorkoutDialogEmail.sendPlanificationToEmail')}
            
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email"
            type="email"
            fullWidth
            onChange={(evt) => setEmail(evt.target.value)}
            variant="standard"
          />
          {request.status === 'success' && (
            <DialogContentText>
              <Alert severity='success'>{t('WorkoutDialogEmail.mailSentTo', { email } )}</Alert>  
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={request.status === 'loading'}>{t('WorkoutDialogEmail.cancel')}</Button>
          <Button type="submit" onClick={handleSendEmail} disabled={request.status === 'loading'}>{t('WorkoutDialogEmail.send')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
