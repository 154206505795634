import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Skeleton } from '@mui/material';
import healthCard from './../components/client/health-card';
import api, { ClientHealth } from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../components/PageTitle';
import ClientQuickView from '../containers/ClientQuickView';
import HealthCard from '../components/client/health-card';


interface ClientHealthsPageProps {
  clientId?: number;
}
const LIMIT = 5;
const ClientHealthsPage: React.FC<ClientHealthsPageProps> = () => {
  const { id = '' } = useParams();
  const clientId = parseInt(id);
  const [healths, setHealths] = useState<ClientHealth[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [request, setRequest] = useState({ status: 'initial', error: '' });
  const navigate = useNavigate();
  const { t } = useTranslation();


  const fetchHealths = async (clientId: number) => {
    setRequest({ status: 'loading', error: '' });
    const response = await api.clients.getHealths(clientId, page, LIMIT);
    if (response.success && response.data) {
      setHealths([ ...healths, ...response.data ]);

      setRequest({ status: 'success', error: '' });
      if (response.data.length < LIMIT) {
        setHasMore(false);
      }
      setPage(page + 1);

    }else {
      setRequest({ status: 'error', error: response.error || 'Error fetching client' });
    }
  };
  const handleNextPage = () => {
    if (!clientId)  return;
    fetchHealths(clientId);
  }
  useEffect(() => {
    if (clientId)
      fetchHealths(clientId);
  }, [clientId]);

  return (
    <Box>
      <PageTitle title={t('ClientHealths.title')} />
      <Box>
        <ClientQuickView clientId={clientId} />
      </Box>
      {(healths.map(health => (
          <Box key={health.id} mb={2}>
            <HealthCard health={health} readonly /> 
          </Box>
        ))
      )}
      {request.status === 'loading' && (
        <Box  width={['100%', '400px']}>
          <Skeleton variant="rectangular" width="100%" height="100px"/>
        </Box>
      )}
      <Box mb={3} mt={2} display="flex" justifyContent="center">
        {hasMore && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextPage}
          >
            {t('common.loadMore')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ClientHealthsPage;