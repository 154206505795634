import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import api from '../../api';
import { extractWorkoutWeekFromText } from '../../utils/json.extract';
import LogoLoading from '../../icons/logoLoading';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const loadingMessages = [
  "LoadingMessages.loadingData",
  "LoadingMessages.generatingMagic",
  "LoadingMessages.pleaseWait",
  "LoadingMessages.almostThere",
  "LoadingMessages.getReady",
  "LoadingMessages.aLittlePatience",
  "LoadingMessages.weAreOnIt",
  "LoadingMessages.dontGoAway",
  "LoadingMessages.soonResults",
  "LoadingMessages.fineTuning",
  "LoadingMessages.adjustingFinalPieces",
  "LoadingMessages.somethingIncredible",
  "LoadingMessages.unfoldingSurprises",
  "LoadingMessages.takeABreath",
  "LoadingMessages.worthTheWait",
  "LoadingMessages.puttingInOrder",
  "LoadingMessages.newsSoon",
  "LoadingMessages.loadingExcitement",
  "LoadingMessages.oneMoreSecond",
  "LoadingMessages.justAMoment",
];

const StepResult: React.FC = () => {
  const { setResult, data } = useWizard();
  const theme = useTheme();
  const [loadingMessage, setLoadingMessage] = useState(loadingMessages[Math.floor(Math.random()*100) % loadingMessages.length]);
  const [result, setResultInternal] = useState<any>({ error: false, data: {}, loading: true });
  const [interval, setIntervalObject] = useState<any>(null);
  const { t } = useTranslation();
  
  const handleSendData = async () => {
    loadingInterval();

    try {
      const res = await api.openai.planify(JSON.stringify(data), !data.clientId);
      if (res.success) {
        const workoutWeek = res.data;
        if (!workoutWeek) { 
          setResultInternal({ error: t('WizardStepResult.planificationCouldntBeGenerated'), data: {} });
        }else {
          setResult(workoutWeek);
        }
      } else {
        setResultInternal({ error: res.error, data: {} });
      }
    }catch (error) {
      setResultInternal({ error: t('WizardStepResult.issueArisedWithAssistant'), data: {} });
    }
    clearInterval(interval)
    setIntervalObject(null);
  };
  const loadingInterval = () => {
    const interval = setInterval(() => {
      setLoadingMessage(prevMessage => {
        const currentIndex = loadingMessages.indexOf(prevMessage);
        const nextIndex = (currentIndex + 1) % loadingMessages.length;
        return loadingMessages[nextIndex];
      });
    }, 3000);
    setIntervalObject(interval);
  } 


  useEffect(() => {
    handleSendData()
  }, []);
  

  return (
    <Box>
      {result.loading && (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <LogoLoading />
          <Typography variant="h4" sx={{ marginTop: 2 }} color={theme.palette.secondary.main}>
            {t('WizardStepResult.loadingData')}
          </Typography>
          <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
            {t(loadingMessage)}
          </Typography>
        </Box>
      )}
      {result.error && (
        <Box>
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            {t('WizardStepResult.errorProcessing')}
          </Typography>
          <Typography variant="subtitle1" sx={{ marginTop: 2, mb: 2 }} color="error.main">
            {result.error?.indexOf('limit') > -1 ? t('WizardStepResult.limitExceeded') : ''}
          </Typography>
          <Link to="/packages">
            <Button variant="contained">
              {t('WizardStepResult.seeProducts')}
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default StepResult;